import React from "react";
import CreatableSelect from "react-select/creatable";

// import { colors } from "@/data/colors";
// import { getColorIndex } from "@/utils/colorHandle";

const SearchSelectCreate = (props) => {
  const {
    options,
    placeholder,
    multi,
    defaultValue,
  } = props;

  // const createOption = (label) => ({
  //     label,
  //     value: label.toLowerCase().replace(/\W/g, ""),
  // });

  //   const getValues = (value) => {
  //     console.log("changeVal", value);
  //     if (value.length == 0) {
  //       props.onChange();
  //     } else {
  //       props.onChange(value);
  //     }
  //   };

  const handleChange = (newValue, actionMeta) => {
    props.onChange(newValue, actionMeta?.action);
  };

  // const handleCreateData = (inputValue) => {
  //     console.log("inputValue", inputValue);

  //     const newOption = createOption(inputValue);
  //     // this.setState({
  //     //   isLoading: false,
  //     //   options: [...options, newOption],
  //     //   value: newOption,
  //     //   formatGroupLabel: "new label",
  //     // });
  //     props.onCreate(newOption);
  // };



  return (
    <CreatableSelect
      isMulti={multi ? true : false}
      options={options}
      // isClearable
      //onChange={(value) => getValues(value)}
      onChange={handleChange}
      // onCreateOption={handleCreateData}
      Placeholder={placeholder}
      defaultValue={typeof defaultValue === "object" ? defaultValue : ""}
      value={defaultValue || null}
    //value={options.find((c) => c.value === testVal)}
    // styles={customSelect ? colourStyles : ""}
    />
  );
};

export default SearchSelectCreate;

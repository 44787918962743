import { useState, useEffect } from 'react';
import {
	Done
} from "../../constants/imagepath";

const RefferalAgentListingCompleted = (props) => {

	const [licenseSpecies, setLicenseSpecies] = useState('');
	const [licenseArea, setLicenseArea] = useState('');

	const SellerDetail = () => {
		return (
			<>

				<div>
					<p className="your_data_has_been_text">Seller Details:</p>

					<table className="submitted_details">
						<tbody>
							<tr>
								<td className='official_number_text'>First Name:</td>
								<td className='right_text'>{props.stateData.firstName ? props.stateData.firstName : 'N/A'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Last Name:</td>
								<td className='right_text'>{props.stateData.lastName ? props.stateData.lastName : 'N/A'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Phone Name:</td>
								<td className='right_text'>{props.stateData.phoneNo ? props.stateData.phoneNo : 'N/A'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Email:</td>
								<td className='right_text'>{props.stateData.email ? props.stateData.email : 'N/A'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Asking Price:</td>
								<td className='right_text'>{props.stateData.askingPrice ? props.stateData.askingPrice : 'N/A'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Location:</td>
								<td className='right_text'>{props.boatAddress ? props.boatAddress : 'N/A'}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</>
		);
	}
	useEffect(() => {
		setLicenseSpecies(getNameById(props.listingOptions.generalSpecies, props.stateData.LicenseSpecies));
		setLicenseArea(getNameById(props.listingOptions.brokerArea, props.stateData.LicenseArea));
		function getNameById(obj, id) {
			if (id > 0) {
				var responseData = Array.from(obj.filter(function (x) {
					return x.id === id
				}));
				return responseData[0].name;
			}
			return 'n/a';
		}
		// eslint-disable-next-line
	});


	return (
		<>
			{(props?.stateData?.ItemType === 'Boat') ?
				<div>
					<center><img src={Done} className="submitted_icon" alt="submit icon" /></center>
					<p className="your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
					<p className="following_text">Following Are The Details Which You Have Submitted</p>
					<table className="submitted_details">
						<tbody>
							<tr>
								<td className='official_number_text'>Official Number</td>
								<td className='right_text'>{props.stateData.boatOfficialNumber ? props.stateData.boatOfficialNumber : 'N/A'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Name of vessel</td>
								<td className='right_text'>{props.stateData.boatName ? props.stateData.boatName : 'N/A'}</td>
							</tr>
							<tr>
								<td className='official_number_text'>Vessel Registration number</td>
								<td className='right_text'>{props.stateData.boatRegNum ? props.stateData.boatRegNum : 'N/A'}</td>
							</tr>
						</tbody>
					</table>
				</div>
				:
				(props.stateData.ItemType === 'License' || props.stateData.ItemType === 'Package' || props.stateData.ItemType === 'Quota')
					?
					<div>
						<center><img src={Done} className="submitted_icon" alt="submit icon" /></center>
						<p className="your_data_has_been_text">Your Data Has Been Submitted <span>Successfully.</span></p>
						<p className="following_text">Following Are The Details Which You Have Submitted</p>
						<table className="submitted_details">
							<tbody>
								<tr>
									<td className='official_number_text'>Name of boat on which license is finished</td>
									<td className='right_text'>{props.stateData.boatName ? props.stateData.boatName : 'N/A'}</td>
								</tr>
								<tr>
									<td className='official_number_text'>License Species</td>
									<td className='right_text'>{licenseSpecies ? licenseSpecies : 'N/A '}</td>
								</tr>
								<tr>
									<td className='official_number_text'>License Area</td>
									<td className='right_text'>{licenseArea ? licenseArea : 'N/A'}</td>
								</tr>
							</tbody>
						</table>
						<SellerDetail></SellerDetail>
					</div>
					:
					<></>
			}
		</>
	);
}

export default RefferalAgentListingCompleted
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../AddUserDetails/AddUserDetails.css";
import AddUserHeader from "./AddUserHeader";
import { useSelector, useDispatch } from "react-redux";
import { userSelector, stepToUserDetail } from "../../store/userSlice";
import { useHistory } from "react-router-dom";

import { Step2 } from "./Step2";
import { Step1 } from "./Step1";
import { useEffect } from "react";



function AddUserDetails(props) {

  const { userData, isSeenSiteOverview, profileStatus } = useSelector(userSelector);
  const dispatch = useDispatch(); // "Function for dispatch an action"
  const history = useHistory();

  const handleClickFunc = (data) => {
    dispatch(stepToUserDetail({ isSeenSiteOverview: data }));
  }
  console.log('userData', userData)

  useEffect(() => {
    if (profileStatus) {
      history.push("/dashboard");
    }
  }, []);

  return (
    <>
      <AddUserHeader />
      <section>
        <div class="container">
          {!isSeenSiteOverview ?
            <Step1 onClick={handleClickFunc} />
            :
            <Step2 userData={userData?.data} />
          }
        </div>
      </section>
    </>
  );
}

export default AddUserDetails;

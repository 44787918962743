import React from "react";
import SellerDetail from './SellerDetail';

const Step3 = ({ handleFormData, handleChangeNormal, values, register, handleAddress, error, clearErrors }) => {
    return (
        <>
            {(values.ItemType === 'License' || values.ItemType === 'Package' || values.ItemType === 'Quota')
                ? <SellerDetail handleFormData={handleFormData} clearErrors={clearErrors} values={values} register={register} error={error} handleAddress={handleAddress} /> :
                <>
                    <p className="good_day_para">Please advise atleast one of the following</p>
                    <div className="row">
                        <div className="col-md-12">
                            <span className="popup_input_label">Official Number(Ship Reg.):</span>
                        </div>
                        <div className="col-md-12">
                            <input type="text" name="boatOfficialNumber" defaultValue={values.boatOfficialNumber} className="popup_input" id="boatOfficialNumber" {...register("boatOfficialNumber", { required: "Required" })} onChange={handleChangeNormal} />
                            {error.boatOfficialNumber && <span className="err-msg">This field is required</span>}
                        </div>

                        <div className="col-md-12">
                            Name of vessel<span className="required-field"></span>
                        </div>
                        <div className="col-md-12">
                            <input type="text" className="popup_input" name="boatName" value={values.boatName} id="boatName" {...register("boatName",
                                {
                                    required: "This field is required",
                                    pattern: {
                                        value: /^[^\s][a-zA-Z\s]+[^\s]$/,
                                        message: "Blank space not allowed in the beginning"
                                    }
                                })}
                                //  onChange={handleFormData}
                                onChange={(e) => {
                                    clearErrors("boatName")
                                    handleFormData(e)
                                }}
                            />
                            {error?.boatName && <span className="err-msg">{error?.boatName?.message}</span>}
                        </div>
                        <div className="col-md-12">
                            Vessel Registration number
                        </div>
                        <div className="col-md-12">
                            <input type="text" className="popup_input" name="boatRegNum" value={values.boatRegNum} id="boatRegNum" {...register("boatRegNum", { required: "Required" })} onChange={handleChangeNormal} />

                        </div>

                    </div>
                </>
            }
        </>
    );
}

export default Step3;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/******* Calling Dynamic URL ********/
const apiUrl = process.env.REACT_APP_API_KEY;

// thunk for call search filter api according to selected listings
export const subFiltersByType = createAsyncThunk(
  "subFiltersByType",
  async ({ type, token }, thunkAPI) => {
    try {
      let endPoints = "search-filters";
      let listingType;
      // console.log("type=>", type);
      switch (type) {
        case "Boat":
          listingType = "boat";
          break;
        case "Pleasure Boats":
          listingType = "pleasure-boat";
          break;
        case "Licenses":
          listingType = "license";
          break;
        case "Packages":
          listingType = "packages";
          break;
        case "Equipment":
          listingType = "equipment";
          break;
        case "Seafood":
          listingType = "seafood";
        case "SeaFood":
          listingType = "seafood";
          break;
        default:
          endPoints = "";
      }
      const response = await fetch(apiUrl + endPoints, {
        method: "POST",
        body: JSON.stringify({
          // your expected POST request payload goes here
          listingType: listingType,
        }),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ` + token,
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();
      if (data.status === true) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// initial state for store listing data
const initialState = {
  isLoader: false,
  isFetching: false,
  isFilterLoader: false,
  listingType: null,
  vesselType: null,
  isMapView: false,
  listingFilters: {},
  searchData: {},
};

// slice for search filters
const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    handleSubFilterByType: (state, action) => {
      state.vesselType = null;
      if (action.payload.type === "type") {
        state.listingType = action.payload.value;
      } else if (action.payload.type === "vessel_type") {
        state.listingType = "vessel";
        state.vesselType = action.payload.value ? action.payload.value : null;
      }
      //state.listingType = action.payload ? action.payload : '';
    },
    updataSearchData: (state, action) => {
      state.searchData = action.payload;
      state.listingType =
        action.payload.listing_type === "SeaFood"
          ? "Seafood"
          : action.payload.listing_type;
    },
    resetAllState: (state) => {
      state.listingType = null;
      state.isFetching = false;
      state.vesselType = null;
      state.listingFilters = {};
    },
    handleSearchFilterData: (state, action) => {
      state.isFilterLoader = action.payload;
    },
    updateFiltersData: (state, action) => {
      state.isMapView = action.payload;
    },
  },
  extraReducers: {
    [subFiltersByType.pending]: (state) => {
      state.isFetching = true;
      state.isLoader = true;
    },
    [subFiltersByType.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.listingFilters = payload.data;
      state.isLoader = false;

      return state;
    },
    [subFiltersByType.rejected]: (state) => {
      state.isFetching = false;
      state.isLoader = false;
      return state;
    },
  },
});

export const filterActions = filterSlice.actions;

export const filterSelector = (state) => state.filter;

export default filterSlice.reducer;

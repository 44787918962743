import React from "react";
import { Progress } from "reactstrap";

const MultiStepProgressBar = ({ percentage }) => {
  // console.log('percentage', percentage)

  return (
    <Progress className="my-3" color="info" value={percentage} />
  );
};

export default MultiStepProgressBar;

import { Url } from "../api_settings/api_constants";
import { wrappedFetch } from "../api_settings/urlFunctions";

//sell button listing store
export const sellButtonSubmit = (data) => {
  return wrappedFetch(Url.SELL_BUUTTON_LISTING_STORE, "POST", data);
};

export const referralRequestSubmit = (data) => {
  return wrappedFetch(Url.BECOME_REFERRAL_REQUEST_STORE, "POST", data);
};

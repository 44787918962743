import React from 'react';
import BuyerDetail from './BuyerDetail';

const Step3 = ({ values, handleFormData, handleButtonInput, error, register, clearErrors, handleAddress }) => {
    return (
        <>

            {(values.ItemType === 'License') || (values.ItemType === 'Package') || (values.ItemType === 'Equipment') || (values.ItemType === 'Seafood')
                ? <BuyerDetail handleFormData={handleFormData} handleAddress={handleAddress} values={values} register={register} clearErrors={clearErrors} error={error} />
                :
                <>
                    <p className="good_day_para">Please Select the Vessel Type</p>
                    <div className="button-wrap">
                        <input className="hidden radio-label" type="radio" name="VesselType" id="fishing-button" value="Fishing" checked={values.VesselType === 'Fishing' ? 'checked' : ''} onClick={handleButtonInput} />
                        <label className="button-label" for="fishing-button">
                            <h1>Fishing</h1>
                        </label>
                        <input className="hidden radio-la`bel" type="radio" name="VesselType" id="craft-button" value="Pleasure Craft" checked={values.VesselType === 'Pleasure Craft' ? 'checked' : ''} onClick={handleButtonInput} />
                        <label className="button-label" for="craft-button">
                            <h1>Pleasure Craft</h1>
                        </label>
                        <input className="hidden radio-label" type="radio" name="VesselType" id="other-button" value="Other" checked={values.VesselType === 'Other' ? 'checked' : ''} onClick={handleButtonInput} />
                        <label className="button-label" for="other-button">
                            <h1>Other</h1>
                        </label>
                    </div>
                </>
            }
        </>
    );
}

export default Step3;

import React from "react";

const Step10 = ({ handleFormData, values }) => {
    return (
        <>
            <p className="good_day_para mt-2">Please Share The Description Of Your Request. You May
                Share Your Boat Name Or Builder That Best Describes Your
                Request.</p>
            <textarea rows="" cols="9" className="textarea" placeholder="Write Here..." name='Description' onChange={handleFormData} defaultValue={values.Description}></textarea>
        </>
    );
}

export default Step10;

import axios from "axios";
import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import SearchSelect from "../../selectSearch/SearchSelect";
import SearchSelectCreate from "../../selectSearch/SearchSelectCreate";

const LicenseListing = ({
  handleFormData,
  values,
  selectValues,
  register,
  error,
  handleLicenseArea,
  clearErrors,
  getValues,
  setValue,
  control,
}) => {
  const [brokerAreas, setbrokerAreas] = useState([]);
  // console.log('selectValues===', selectValues)

  useEffect(
    () => {
      console.log("useeffect logged");
      getBrokerAreas(getValues("LicenseRegion"), getValues("LicenseSpecies"));
    },
    // eslint-disable-next-line
    [getValues("LicenseArea")]
  );

  console.log("getValue===>", getValues("LicenseArea"));
  function handleArea(data, fieldType) {
    const name = fieldType;
    // const value = evt.target.value;
    let region;
    let species;

    if (name === "LicenseRegion") {
      region = data.value;
      species = values.LicenseSpecies;
    } else if (name === "LicenseSpecies") {
      species = data.value;
      region = values.LicenseRegion;
    }

    if (region > 0 && species > 0) {
      getBrokerAreas(region, species);
    }
  }

  function getBrokerAreas(region, species) {
    console.log("region=" + region + "specie=" + species);
    selectValues.brokerArea = [];
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_API_KEY +
        "get-region-species-area/" +
        region +
        "/" +
        species +
        "/0",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        setbrokerAreas(response.data.data);
        handleLicenseArea(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <p className="good_day_para">
        Please fill the following details for License
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              Species <span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseSpecies"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    if (selectedOption.value != getValues("LicenseSpecies")) {
                      setValue("LicenseArea", "");
                    }
                    setValue("LicenseSpecies", selectedOption?.value);
                    handleArea(selectedOption, "LicenseSpecies");
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "LicenseSpecies",
                      },
                    });
                    clearErrors("LicenseSpecies");
                  }}
                  defaultValue={
                    selectValues?.generalSpecies.find(
                      (e) => e.id === getValues("LicenseSpecies")
                    )
                      ? {
                        value: selectValues?.generalSpecies.find(
                          (e) => e.id === getValues("LicenseSpecies")
                        ).id,
                        label: selectValues?.generalSpecies.find(
                          (e) => e.id === getValues("LicenseSpecies")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues.generalSpecies.map((berth, index) => {
                    return { value: berth.id, label: berth.name };
                  })}
                />
              )}
            />

            {/* {error.LicenseSpecies && <span className="err-msg">This field is required</span>} */}

            {error?.LicenseSpecies && (
              <span className="err-msg">{error?.LicenseSpecies?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Region <span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            {/* <select className="popup_input" id="species" name="LicenseRegion"  {...register("LicenseRegion", { required: "This field is required." })}
                            onChange={e => { handleArea(e); handleFormData(e); clearErrors('LicenseRegion') }}>
                            <option value="">Choose...</option>
                            {selectValues.brokerRegion.map((region, index) => (
                                <option value={region.id}>{region.name}</option>
                            ))}
                        </select> */}
            <Controller
              name="LicenseRegion"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("LicenseRegion", selectedOption?.value);
                    handleArea(selectedOption, "LicenseRegion");
                    handleFormData({
                      target: {
                        value: selectedOption.value,
                        name: "LicenseRegion",
                      },
                    });
                    clearErrors("LicenseRegion");
                  }}
                  defaultValue={
                    selectValues?.brokerRegion.find(
                      (e) => e.id === getValues("LicenseRegion")
                    )
                      ? {
                        value: selectValues?.brokerRegion.find(
                          (e) => e.id === getValues("LicenseRegion")
                        ).id,
                        label: selectValues?.brokerRegion.find(
                          (e) => e.id === getValues("LicenseRegion")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues.brokerRegion.map((region, index) => {
                    return { value: region.id, label: region.name };
                  })}
                />
              )}
            />
            {error?.LicenseRegion && (
              <span className="err-msg">{error?.LicenseRegion?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              License Area <span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseArea"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("LicenseArea", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "LicenseArea",
                      },
                    });
                    clearErrors("LicenseArea");
                  }}
                  defaultValue={
                    brokerAreas.find((e) => e.id === getValues("LicenseArea"))
                      ? {
                        value: brokerAreas.find(
                          (e) => e.id === getValues("LicenseArea")
                        ).id,
                        label: brokerAreas.find(
                          (e) => e.id === getValues("LicenseArea")
                        )?.name,
                      }
                      : ""
                  }
                  options={brokerAreas?.map((region, index) => {
                    return { value: region.id, label: region.name };
                  })}
                />
              )}
            />
            {error?.LicenseArea && (
              <span className="err-msg">{error?.LicenseArea?.message}</span>
            )}
          </div>

          <div className="col-md-12">
            <span className="popup_input_label">
              Province<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            {/* <select className="popup_input" id="province" name="LicenseProvince"  {...register("LicenseProvince", { required: "This field is required." })}
                            onChange={(e) => {
                                clearErrors("LicenseProvince")
                                handleFormData(e)
                            }}
                        >
                            <option value="">Choose...</option>
                            {selectValues?.states?.map((berth, index) => (
                                <option value={berth.id}>{berth.name}</option>
                            ))}
                        </select> */}
            <Controller
              name="LicenseProvince"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("LicenseProvince", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "LicenseProvince",
                      },
                    });
                    clearErrors("LicenseProvince");
                  }}
                  defaultValue={
                    selectValues?.states.find(
                      (e) => e.id === getValues("LicenseProvince")
                    )
                      ? {
                        value: selectValues?.states.find(
                          (e) => e.id === getValues("LicenseProvince")
                        ).id,
                        label: selectValues?.states.find(
                          (e) => e.id === getValues("LicenseProvince")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.states?.map((berth, index) => {
                    return { value: berth.id, label: berth.name };
                  })}
                />
              )}
            />
            {error?.LicenseProvince && (
              <span className="err-msg">{error?.LicenseProvince?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              License Type<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="LicenseType"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelectCreate
                  onChange={(selectedOption) => {
                    setValue("LicenseType", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "LicenseType",
                      },
                    });
                    clearErrors("LicenseType");
                  }}
                  defaultValue={
                    selectValues?.brokerLicenseTypeGeneral.find(
                      (e) => e.id === getValues("LicenseType")
                    )
                      ? {
                        value: selectValues?.brokerLicenseTypeGeneral.find(
                          (e) => e.id === getValues("LicenseType")
                        ).id,
                        label: selectValues?.brokerLicenseTypeGeneral.find(
                          (e) => e.id === getValues("LicenseType")
                        )?.name,
                      }
                      : {
                        label: getValues("LicenseType"),
                        value: getValues("LicenseType"),
                      }
                  }
                  options={selectValues?.brokerLicenseTypeGeneral?.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error?.LicenseType && (
              <span className="err-msg">{error?.LicenseType?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">License Or Quota</span>
          </div>
          <div className="col-md-12">
            <select
              className="popup_input"
              id="license_or_quota"
              name="LicenseOrQuota"
              onChange={handleFormData}
            >
              <option value="9" selected>
                License
              </option>
              <option value="8" selected>
                Quota
              </option>
            </select>
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Min Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MinPriceWillingToPay"
              defaultValue={values.MinPriceWillingToPay}
              className="popup_input"
              id="MinPriceWillingToPay"
              {...register("MinPriceWillingToPay", {
                required: "This field is required.",
                valueAsNumber: true,
                validate: {
                  required: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Minimum price should not be less than or equal to $100";
                    } else if (value >= getValues("MaxPriceWillingToPay")) {
                      return "Minimum price should not be greater or equal to maximum price";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MinPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MinPriceWillingToPay && (
              <span className="err-msg">
                {error?.MinPriceWillingToPay?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Max Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MaxPriceWillingToPay"
              defaultValue={values.MaxPriceWillingToPay}
              className="popup_input"
              id="ItemPrice"
              {...register("MaxPriceWillingToPay", {
                required: "This field is required.",
                validate: {
                  maxNumber: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Maximum price should not be less than or equal to $100";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MaxPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MaxPriceWillingToPay && (
              <span className="err-msg">
                {error?.MaxPriceWillingToPay?.message}
              </span>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const PackageListing = ({ handleFormData, values }) => {
  return (
    <>
      <div className="step well ">
        <div className="midBoxBut">
          <p>Please advise for the PackageListing</p>
          <form>
            <div className="form-group">
              <label htmlFor="usr">License Number(ship reg.):</label>
              brokerEquipmentManufacturer
              <input
                type="text"
                name="OfficialNumber"
                onChange={handleFormData}
                defaultValue={values.OfficialNumber}
                className="form-control"
                id="OfficialNumber"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const EquipmentListing = ({
  handleFormData,
  values,
  selectValues,
  register,
  error,
  clearErrors,
  getValues,
  setValue,
  control,
}) => {
  // console.log('selectValues===', selectValues)

  return (
    <>
      <p className="good_day_para">
        Please fill the following details for Equipment/Gear
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              Category<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            {/* <select className="popup_input" id="species" name="EquipmentCategory"  {...register("EquipmentCategory", { required: "This field is required." })}
                            onChange={(e) => {
                                clearErrors("EquipmentCategory");
                                handleFormData(e);
                            }}
                        >
                            <option value="">Choose...</option>
                            {selectValues.brokerEquipmentCategory.map((berth, index) => (
                                <option value={berth.id}>{berth.name}</option>
                            ))}
                        </select> */}
            <Controller
              name="EquipmentCategory"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("EquipmentCategory", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "EquipmentCategory",
                      },
                    });
                    clearErrors("EquipmentCategory");
                  }}
                  defaultValue={
                    selectValues?.brokerEquipmentCategory.find(
                      (e) => e.id === getValues("EquipmentCategory")
                    )
                      ? {
                        value: selectValues?.brokerEquipmentCategory.find(
                          (e) => e.id === getValues("EquipmentCategory")
                        ).id,
                        label: selectValues?.brokerEquipmentCategory.find(
                          (e) => e.id === getValues("EquipmentCategory")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.brokerEquipmentCategory?.map(
                    (berth, index) => {
                      return { value: berth.id, label: berth.name };
                    }
                  )}
                />
              )}
            />
            {error?.EquipmentCategory && (
              <span className="err-msg">
                {error?.EquipmentCategory?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Product Type<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            {/* <select className="popup_input" id="species"  {...register("EquipmentProduct", { required: "This field is required." })} name="EquipmentProduct"
                            onChange={(e) => {
                                clearErrors("EquipmentProduct");
                                handleFormData(e);
                            }}
                        >
                            <option value="">Choose...</option>
                            {selectValues.brokerEquipmentProduct.filter((e) => e.category === values?.EquipmentCategory).map((berth, index) => (
                                <option value={berth.id}>{berth.name}</option>
                            ))}
                        </select> */}
            <Controller
              name="EquipmentProduct"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("EquipmentProduct", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "EquipmentProduct",
                      },
                    });
                    clearErrors("EquipmentProduct");
                  }}
                  defaultValue={
                    selectValues?.brokerEquipmentProduct.find(
                      (e) => e.id === getValues("EquipmentProduct")
                    )
                      ? {
                        value: selectValues?.brokerEquipmentProduct.find(
                          (e) => e.id === getValues("EquipmentProduct")
                        ).id,
                        label: selectValues?.brokerEquipmentProduct.find(
                          (e) => e.id === getValues("EquipmentProduct")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues.brokerEquipmentProduct
                    .filter((e) => e.category === values?.EquipmentCategory)
                    .map((berth, index) => {
                      return { value: berth.id, label: berth.name };
                    })}
                />
              )}
            />
            {error?.EquipmentProduct && (
              <span className="err-msg">
                {error?.EquipmentProduct?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Manufacturer<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            {/* <select className="popup_input" id="EquipmentManufacturer"  {...register("EquipmentManufacturer", { required: "This field is required" })} name="EquipmentManufacturer"
                            onChange={(e) => {
                                clearErrors("EquipmentManufacturer")
                                handleFormData(e)
                            }}>
                            <option value="">Choose...</option>
                            {selectValues.brokerEquipmentManufacturer.map((manufacturer, index) => (
                                <option value={manufacturer.id}>{manufacturer.name}</option>
                            ))}
                        </select> */}
            <Controller
              name="EquipmentManufacturer"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("EquipmentManufacturer", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "EquipmentManufacturer",
                      },
                    });
                    clearErrors("EquipmentManufacturer");
                  }}
                  defaultValue={
                    selectValues?.brokerEquipmentManufacturer.find(
                      (e) => e.id === getValues("EquipmentManufacturer")
                    )
                      ? {
                        value: selectValues?.brokerEquipmentManufacturer.find(
                          (e) => e.id === getValues("EquipmentManufacturer")
                        ).id,
                        label: selectValues?.brokerEquipmentManufacturer.find(
                          (e) => e.id === getValues("EquipmentManufacturer")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.brokerEquipmentManufacturer?.map(
                    (manufacturer, index) => {
                      return {
                        value: manufacturer.id,
                        label: manufacturer.name,
                      };
                    }
                  )}
                />
              )}
            />
            {error?.EquipmentManufacturer && (
              <span className="err-msg">
                {error?.EquipmentManufacturer?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Province<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            {/* <select className="popup_input" id="province"  {...register("EquipmentProvince", { required: "This field is required." })}
                            onChange={(e) => {
                                clearErrors("EquipmentProvince")
                                handleFormData(e)
                            }}
                            name="EquipmentProvince">
                            <option value="">Choose...</option>
                            {selectValues?.states?.map((berth, index) => (
                                <option value={berth.id}>{berth.name}</option>
                            ))}
                        </select> */}
            <Controller
              name="EquipmentProvince"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("EquipmentProvince", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "EquipmentProvince",
                      },
                    });
                    clearErrors("EquipmentProvince");
                  }}
                  defaultValue={
                    selectValues?.states.find(
                      (e) => e.id === getValues("EquipmentProvince")
                    )
                      ? {
                        value: selectValues?.states.find(
                          (e) => e.id === getValues("EquipmentProvince")
                        ).id,
                        label: selectValues?.states.find(
                          (e) => e.id === getValues("EquipmentProvince")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.states?.map((manufacturer, index) => {
                    return { value: manufacturer.id, label: manufacturer.name };
                  })}
                />
              )}
            />
            {error?.EquipmentProvince && (
              <span className="err-msg">
                {error?.EquipmentProvince?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Min Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MinPriceWillingToPay"
              defaultValue={values.MinPriceWillingToPay}
              className="popup_input"
              id="MinPriceWillingToPay"
              {...register("MinPriceWillingToPay", {
                required: "This field is required.",
                valueAsNumber: true,
                validate: {
                  required: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Minimum price should not be less than or equal to $100";
                    } else if (value >= getValues("MaxPriceWillingToPay")) {
                      return "Minimum price should not be greater or equal to maximum price";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MinPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MinPriceWillingToPay && (
              <span className="err-msg">
                {error?.MinPriceWillingToPay?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Max Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MaxPriceWillingToPay"
              defaultValue={values.MaxPriceWillingToPay}
              className="popup_input"
              id="ItemPrice"
              {...register("MaxPriceWillingToPay", {
                required: "This field is required.",
                validate: {
                  maxNumber: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Maximum price should not be less than or equal to $100";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MaxPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MaxPriceWillingToPay && (
              <span className="err-msg">
                {error?.MaxPriceWillingToPay?.message}
              </span>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const SeaFoodListing = ({
  handleFormData,
  values,
  selectValues,
  register,
  error,
  clearErrors,
  getValues,
  setValue,
  control,
}) => {
  const [countries, setCountries] = useState([]);

  useEffect(
    () => {
      console.log("console logged success");
      console.log("console logged get Value", getValues("SeaFoodProvince"));

      handleCountries(getValues("SeaFoodProvince"));
    },
    // eslint-disable-next-line
    [getValues("SeaFoodCountry")]
  );

  function handleCountries(evt) {
    var data = new FormData();
    data.append("state_id", evt);
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_KEY + "get-countries",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setCountries(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <p className="good_day_para">
        Please fill the following details for Seafood
      </p>
      <form>
        <div className="row">
          <div className="col-md-12">
            <span className="popup_input_label">
              Region<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodRegion"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("SeaFoodRegion", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "SeaFoodRegion",
                      },
                    });
                    clearErrors("SeaFoodRegion");
                  }}
                  defaultValue={
                    selectValues?.brokerRegion.find(
                      (e) => e.id === getValues("SeaFoodRegion")
                    )
                      ? {
                        value: selectValues?.brokerRegion.find(
                          (e) => e.id === getValues("SeaFoodRegion")
                        ).id,
                        label: selectValues?.brokerRegion.find(
                          (e) => e.id === getValues("SeaFoodRegion")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.brokerRegion?.map(
                    (manufacturer, index) => {
                      return {
                        value: manufacturer.id,
                        label: manufacturer.name,
                      };
                    }
                  )}
                />
              )}
            />
            {error.SeaFoodRegion && (
              <span className="err-msg">{error.SeaFoodRegion.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              General Species<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodSpecies"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("SeaFoodSpecies", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "SeaFoodSpecies",
                      },
                    });
                    clearErrors("SeaFoodSpecies");
                  }}
                  defaultValue={
                    selectValues?.brokerGeneralSpecies.find(
                      (e) => e.id === getValues("SeaFoodSpecies")
                    )
                      ? {
                        value: selectValues?.brokerGeneralSpecies.find(
                          (e) => e.id === getValues("SeaFoodSpecies")
                        ).id,
                        label: selectValues?.brokerGeneralSpecies.find(
                          (e) => e.id === getValues("SeaFoodSpecies")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.brokerGeneralSpecies?.map(
                    (manufacturer, index) => {
                      return {
                        value: manufacturer.id,
                        label: manufacturer.name,
                      };
                    }
                  )}
                />
              )}
            />
            {error.SeaFoodSpecies && (
              <span className="err-msg">{error.SeaFoodSpecies.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Size<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodSize"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("SeaFoodSize", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "SeaFoodSize",
                      },
                    });
                    clearErrors("SeaFoodSize");
                  }}
                  defaultValue={
                    selectValues?.brokerGeneralSpeciesSize.find(
                      (e) => e.id === getValues("SeaFoodSize")
                    )
                      ? {
                        value: selectValues?.brokerGeneralSpeciesSize.find(
                          (e) => e.id === getValues("SeaFoodSize")
                        ).id,
                        label: selectValues?.brokerGeneralSpeciesSize.find(
                          (e) => e.id === getValues("SeaFoodSize")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.brokerGeneralSpeciesSize?.map(
                    (manufacturer, index) => {
                      return {
                        value: manufacturer.id,
                        label: manufacturer.name,
                      };
                    }
                  )}
                />
              )}
            />
            {error.SeaFoodSize && (
              <span className="err-msg">{error.SeaFoodSize.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Min Quantity<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type=""
              name="SeaFoodMinQuantity"
              defaultValue={values.SeaFoodMinQuantity}
              className="popup_input"
              id="SeaFoodMinQuantity"
              {...register("SeaFoodMinQuantity", {
                required: "This field is required.",
                valueAsNumber: true,
                validate: {
                  required: (value) => {
                    if (value >= getValues("SeaFoodMaxQuantity")) {
                      return "Minimum quantity should not be greater or equal to maximum quantity";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("SeaFoodMinQuantity");
                handleFormData(e);
              }}
            />
            {error.SeaFoodMinQuantity && (
              <span className="err-msg">
                {error.SeaFoodMinQuantity.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Max Quantity<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type=""
              name="SeaFoodMaxQuantity"
              defaultValue={values.SeaFoodMaxQuantity}
              className="popup_input"
              id="SeaFoodMaxQuantity"
              {...register("SeaFoodMaxQuantity", {
                required: "This field is required",
              })}
              onChange={(e) => {
                clearErrors("SeaFoodMaxQuantity");
                handleFormData(e);
              }}
            />
            {error?.SeaFoodMaxQuantity && (
              <span className="err-msg">
                {error?.SeaFoodMaxQuantity?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Province<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodProvince"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    if (selectedOption.value != getValues("SeaFoodProvince")) {
                      setValue("SeaFoodCOuntry", "");
                    }
                    setValue("SeaFoodProvince", selectedOption?.value);
                    handleCountries(selectedOption.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "SeaFoodProvince",
                      },
                    });
                    clearErrors("SeaFoodProvince");
                  }}
                  defaultValue={
                    selectValues?.states.find(
                      (e) => e.id === getValues("SeaFoodProvince")
                    )
                      ? {
                        value: selectValues?.states.find(
                          (e) => e.id === getValues("SeaFoodProvince")
                        ).id,
                        label: selectValues?.states.find(
                          (e) => e.id === getValues("SeaFoodProvince")
                        )?.name,
                      }
                      : ""
                  }
                  options={selectValues?.states?.map((state, index) => {
                    return { value: state.id, label: state.name };
                  })}
                />
              )}
            />
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Country<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <Controller
              name="SeaFoodCountry"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <SearchSelect
                  onChange={(selectedOption) => {
                    setValue("SeaFoodCountry", selectedOption?.value);
                    handleFormData({
                      target: {
                        value: selectedOption?.value,
                        name: "SeaFoodCountry",
                      },
                    });
                    clearErrors("SeaFoodCountry");
                  }}
                  defaultValue={
                    countries.find((e) => e.id === getValues("SeaFoodCountry"))
                      ? {
                        value: countries.find(
                          (e) => e.id === getValues("SeaFoodCountry")
                        ).id,
                        label: countries.find(
                          (e) => e.id === getValues("SeaFoodCountry")
                        )?.name,
                      }
                      : ""
                  }
                  options={countries?.map((state, index) => {
                    return { value: state.id, label: state.name };
                  })}
                />
              )}
            />
            {error?.SeaFoodCountry && (
              <span className="err-msg">{error?.SeaFoodCountry?.message}</span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Min Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MinPriceWillingToPay"
              defaultValue={values.MinPriceWillingToPay}
              className="popup_input"
              id="MinPriceWillingToPay"
              {...register("MinPriceWillingToPay", {
                required: "This field is required.",
                valueAsNumber: true,
                validate: {
                  required: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Minimum price should not be less than or equal to $100";
                    } else if (value >= getValues("MaxPriceWillingToPay")) {
                      return "Minimum price should not be greater or equal to maximum price";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MinPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MinPriceWillingToPay && (
              <span className="err-msg">
                {error?.MinPriceWillingToPay?.message}
              </span>
            )}
          </div>
          <div className="col-md-12">
            <span className="popup_input_label">
              Max Price<span className="required-field"></span>
            </span>
          </div>
          <div className="col-md-12">
            <input
              type="number"
              min="100"
              name="MaxPriceWillingToPay"
              defaultValue={values.MaxPriceWillingToPay}
              className="popup_input"
              id="ItemPrice"
              {...register("MaxPriceWillingToPay", {
                required: "This field is required.",
                validate: {
                  maxNumber: (value) => {
                    if (parseFloat(value) <= 100) {
                      return "Maximum price should not be less than or equal to $100";
                    }
                  },
                },
              })}
              onChange={(e) => {
                clearErrors("MaxPriceWillingToPay");
                handleFormData(e);
              }}
            />
            {error.MaxPriceWillingToPay && (
              <span className="err-msg">
                {error?.MaxPriceWillingToPay?.message}
              </span>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const Step4 = ({
  userName,
  handleFormData,
  values,
  register,
  error,
  selectValues,
  handleLicenseArea,
  clearErrors,
  getValues,
  setValue,
  control,
}) => {
  const [placeHolderValue, setPlaceholderValue] = useState("FT-IN");
  const [checklength, setCheckLength] = useState("");
  const [checkWidth, setCheckWidth] = useState("");

  // useEffect for compare min length and max length
  useEffect(() => {
    const minTotalCm =
      values?.MinLengthFoot * 30.48 + values?.MinLengthInch * 2.54;
    const maxTotalCm =
      values?.MaxLengthFoot * 30.48 + values?.MaxLengthInch * 2.54;

    if (minTotalCm >= maxTotalCm) {
      return setCheckLength(
        "Minimum length should not be greater or equal maximum length"
      );
    } else {
      return setCheckLength("");
    }
  }, [
    values?.MinLengthFoot,
    values?.MinLengthInch,
    values?.MaxLengthFoot,
    values?.MaxLengthInch,
  ]);

  useEffect(() => {
    const minTotalCm =
      values?.MinWidthFoot * 30.48 + values?.MinWidthInch * 2.54;
    const maxTotalCm =
      values?.MaxWidthFoot * 30.48 + values?.MaxWidthInch * 2.54;

    if (minTotalCm >= maxTotalCm) {
      return setCheckWidth(
        "Minimum width should not be greater or equal maximum width"
      );
    } else {
      return setCheckWidth("");
    }
  }, [
    values?.MinWidthFoot,
    values?.MinWidthInch,
    values?.MaxWidthFoot,
    values?.MaxWidthInch,
  ]);

  return (
    <>
      {values.ItemType === "License" ? (
        <LicenseListing
          handleFormData={handleFormData}
          values={values}
          selectValues={selectValues}
          clearErrors={clearErrors}
          getValues={getValues}
          register={register}
          error={error}
          handleLicenseArea={handleLicenseArea}
          setValue={setValue}
          control={control}
        />
      ) : values.ItemType === "Package" ? (
        <PackageListing
          handleFormData={handleFormData}
          clearErrors={clearErrors}
          getValues={getValues}
          values={values}
          setValue={setValue}
          control={control}
        />
      ) : values.ItemType === "Equipment" ? (
        <EquipmentListing
          handleFormData={handleFormData}
          values={values}
          selectValues={selectValues}
          clearErrors={clearErrors}
          getValues={getValues}
          register={register}
          error={error}
          setValue={setValue}
          control={control}
        />
      ) : values.ItemType === "Seafood" ? (
        <SeaFoodListing
          handleFormData={handleFormData}
          values={values}
          selectValues={selectValues}
          clearErrors={clearErrors}
          getValues={getValues}
          register={register}
          error={error}
          setValue={setValue}
          control={control}
        />
      ) : values.ItemType === "Boat" ? (
        <>
          <p className="good_day_para">
            Please Provide The Basic Vessel Measurements
          </p>
          <span className="popup_input_label">
            What Is The Minimum Length: *
          </span>
          <div className="measurements">
            <label for="input1" className="input1">
              <input
                id="input1"
                type="text"
                name="MinLengthFoot"
                defaultValue={values?.MinLengthFoot}
                placeholder="Ex: 80"
                {...register("MinLengthFoot", {
                  required: "This field is required.",
                  validate: {
                    required: (value) => {
                      if (parseFloat(value) <= 0) {
                        return "Min length should be greater than zero.";
                      }
                      if (checklength?.length !== 0) {
                        return checklength;
                      }
                    },
                  },
                })}
                onChange={(e) => {
                  clearErrors("MinLengthFoot");
                  handleFormData(e);
                }}
              />
              <span>Feets</span>
            </label>
            <label for="input1" className="input1">
              <input
                id="input1"
                type="text"
                placeholder="Ex: 10"
                name="MinLengthInch"
                defaultValue={values?.MinLengthInch}
                {...register("MinLengthInch", {
                  required: "This field is required.",
                  validate: {
                    required: (value) => {
                      if (parseFloat(value) <= 0) {
                        return "Min inch should be greater than zero.";
                      }
                    },
                  },
                  validate: {
                    maxNumber: (value) => {
                      if (placeHolderValue === "FT-IN") {
                        if (value > 11) {
                          return "Inch should not be greater than 11";
                        }
                      } else {
                        if (value > 99) {
                          return "Cm should not be greater than 99";
                        }
                      }
                    },
                  },
                })}
                onChange={(e) => {
                  clearErrors("MinLengthInch");
                  handleFormData(e);
                }}
              />
              <span>Inches</span>
            </label>
            <div className="select-dropdown">
              <div className="dropdown">
                <select
                  className="custom-select mr-sm-2 col-md-12"
                  id="inlineFormCustomSelect"
                  onChange={(e) => {
                    handleFormData(e);
                    setPlaceholderValue(e.target.value);
                  }}
                  value={selectValues.LengthUnit}
                  name="LengthUnit"
                >
                  <option value="FT-IN" selected={true}>
                    Feet-Inches
                  </option>
                  <option value="MT-CM">Meter-Cm</option>
                </select>
              </div>
            </div>
          </div>
          {(error?.MinLengthFoot || error?.MinLengthInch) && (
            <span className="err-msg">
              {error.MinLengthFoot
                ? error?.MinLengthFoot?.message
                : error?.MinLengthInch?.message}
            </span>
          )}
          <span className="popup_input_label">
            What Is The Maximum Length: *
          </span>
          <div className="measurements">
            <label for="input1" className="input1">
              <input
                id="input1"
                type="text"
                placeholder="Ex: 60"
                name="MaxLengthFoot"
                defaultValue={values.MaxLengthFoot}
                {...register("MaxLengthFoot", {
                  required: "This field is required.",
                  validate: {
                    required: (value) => {
                      if (parseFloat(value) <= 0)
                        return "Min length should be greater than zero.";
                    },
                  },
                })}
                onChange={(e) => {
                  clearErrors("MaxLengthFoot");
                  handleFormData(e);
                }}
              />
              <span>Feets</span>
            </label>
            <label for="input1" className="input1">
              <input
                id="input1"
                type="text"
                placeholder="Ex: 07"
                name="MaxLengthInch"
                defaultValue={values.MaxLengthInch}
                {...register("MaxLengthInch", {
                  required: "This field is required.",
                  validate: {
                    maxNumber: (value) => {
                      if (placeHolderValue === "FT-IN") {
                        if (value > 12) {
                          return "Inch should not be greater than 11";
                        }
                      } else {
                        if (value > 99) {
                          return "Cm should not be greater than 99";
                        }
                      }
                    },
                  },
                })}
                onChange={(e) => {
                  clearErrors("MaxLengthInch");
                  handleFormData(e);
                }}
              />
              <span>Inches</span>
            </label>
            <div className="select-dropdown">
              <div className="dropdown">
                <select
                  className="custom-select mr-sm-2 col-md-12"
                  id="inlineFormCustomSelect"
                  onChange={(e) => {
                    handleFormData(e);
                    setPlaceholderValue(e.target.value);
                  }}
                  value={selectValues.LengthUnit}
                  name="LengthUnit"
                >
                  <option value="FT-IN" selected={true}>
                    Feet-Inches
                  </option>
                  <option value="MT-CM">Meter-Cm</option>
                </select>
              </div>
            </div>
          </div>
          {(error?.MaxLengthFoot || error?.MaxLengthInch) && (
            <span className="err-msg">
              {error?.MaxLengthFoot
                ? error?.MaxLengthFoot?.message
                : error?.MaxLengthInch.message}
            </span>
          )}
          <span className="popup_input_label">
            What Is The Minimum Width: *
          </span>
          <div className="measurements">
            <label for="input1" className="input1">
              <input
                id="input1"
                type="text"
                placeholder="Ex: 15"
                name="MinWidthFoot"
                defaultValue={values?.MinWidthFoot}
                {...register("MinWidthFoot", {
                  required: "This field is required.",
                  validate: {
                    required: (value) => {
                      if (parseFloat(value) <= 0) {
                        return "Min length should be greater than zero.";
                      }
                      if (checklength?.length !== 0) {
                        return checklength;
                      }
                    },
                  },
                })}
                onChange={(e) => {
                  clearErrors("MinWidthFoot");
                  handleFormData(e);
                }}
              />
              <span>Feets</span>
            </label>
            <label for="input1" className="input1">
              <input
                id="input1"
                type="text"
                placeholder="Ex: 10"
                name="MinWidthInch"
                defaultValue={values?.MinWidthInch}
                {...register("MinWidthInch", {
                  required: "This field is required.",
                  validate: {
                    maxNumber: (value) => {
                      if (placeHolderValue === "FT-IN") {
                        if (parseFloat(value) > 11) {
                          return "Inch should not be greater than 11";
                        }
                      } else {
                        if (parseFloat(value) > 99) {
                          return "Cm should not be greater than 99";
                        }
                      }
                    },
                  },
                })}
                onChange={(e) => {
                  clearErrors("MinWidthInch");
                  handleFormData(e);
                }}
              />
              <span>Inches</span>
            </label>
            <div className="select-dropdown">
              <div className="dropdown">
                <select
                  className="custom-select mr-sm-2 col-md-12"
                  id="inlineFormCustomSelect"
                  onChange={(e) => {
                    handleFormData(e);
                    setPlaceholderValue(e.target.value);
                  }}
                  value={selectValues.LengthUnit}
                  name="LengthUnit"
                >
                  <option value="FT-IN" selected={true}>
                    Feet-Inches
                  </option>
                  <option value="MT-CM">Meter-Cm</option>
                </select>
              </div>
            </div>
          </div>
          {(error.MinWidthFoot || error.MinWidthInch) && (
            <span className="err-msg">
              {error.MinWidthFoot
                ? error.MinWidthFoot.message
                : error.MinWidthInch.message}
            </span>
          )}
          <span className="popup_input_label">
            What Is The Maximum Width: *
          </span>
          <div className="measurements">
            <label for="input1" className="input1">
              <input
                id="input1"
                type="text"
                placeholder="Ex: 15"
                name="MaxWidthFoot"
                defaultValue={values.MaxWidthFoot}
                {...register("MaxWidthFoot", {
                  required: "This field is required.",
                  validate: {
                    required: (value) => {
                      if (parseFloat(value) <= 0)
                        return "Min length should be greater than zero.";
                    },
                  },
                })}
                onChange={(e) => {
                  clearErrors("MaxWidthFoot");
                  handleFormData(e);
                }}
              />
              <span>Feets</span>
            </label>
            <label for="input1" className="input1">
              <input
                id="input1"
                type="text"
                placeholder="Ex: 10"
                name="MaxWidthInch"
                defaultValue={values.MaxWidthInch}
                {...register("MaxWidthInch", {
                  required: "This field is required.",
                  validate: {
                    maxNumber: (value) => {
                      if (placeHolderValue === "FT-IN") {
                        if (parseFloat(value) > 11) {
                          return "Inch should not be greater than 11";
                        }
                      } else {
                        if (parseFloat(value) > 99) {
                          return "Cm should not be greater than 99";
                        }
                      }
                    },
                  },
                })}
                onChange={(e) => {
                  clearErrors("MaxWidthInch");
                  handleFormData(e);
                }}
              />
              <span>Inches</span>
            </label>
            <div className="select-dropdown">
              <div className="dropdown">
                <select
                  className="custom-select mr-sm-2 col-md-12"
                  id="inlineFormCustomSelect"
                  onChange={(e) => {
                    handleFormData(e);
                    setPlaceholderValue(e.target.value);
                  }}
                  value={selectValues.LengthUnit}
                  name="LengthUnit"
                >
                  <option value="FT-IN" selected={true}>
                    Feet-Inches
                  </option>
                  <option value="MT-CM">Meter-Cm</option>
                </select>
              </div>
            </div>
          </div>
          {(error.MaxWidthFoot || error.MaxWidthInch) && (
            <span className="err-msg">
              {error.MaxWidthFoot
                ? error.MaxWidthFoot.message
                : error.MaxWidthInch.message}
            </span>
          )}
        </>
      ) : (
        <div className="step well ">
          <div className="midBoxBut">
            <h3 style={{ color: "red" }}>Please select an option</h3>
          </div>
        </div>
      )}
    </>
  );
};

export default Step4;

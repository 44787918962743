import React, { useState, useEffect } from "react";
import AddUserHeader from "./AddUserDetails/AddUserHeader";
import {
    getContent
} from "../api_services/api_calls/contentPageApi";
// "import component via react-lazy load"


export default function Page({ match }) {
    const { slug } = match.params; // Access the slug from URL params
    // Fetch data based on the slug, or use a static mapping
    const [pageTitle, setPageTitle] = useState("");
    const [pageContent, setPageContent] = useState("");

    useEffect(() => {
        getContent(slug) // Api function for get page content via api services
            .then((response) => {
                console.log('resp', response)
                setPageTitle(response.data.data.page_title)
                setPageContent(response.data.data.contents)

            })
            .catch((error) => {
                console.log('error');
            });


    }, []);

    console.log(pageTitle)
    return (
        <>
            <AddUserHeader />
            <div className="container" style={{ marginTop: "100px", marginBottom: "200px" }}>
                <div className="row">
                    <h2 className="col-md-12 text-center text-uppercase">{pageTitle}</h2><br />

                    <div className="col-md-12">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: pageContent,
                            }}
                        ></p>
                    </div>

                </div>
            </div>
        </>
    );
}

import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

function Example(args) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div>
     TEsasd
    </div>
  );
}

Example.args = {
  autohide: true,
  flip: true,
};

Example.argTypes = {
  placement: {
    control: { type: 'select' },
    options: ['top', 'left', 'right', 'bottom'],
  },
};

export default Example;
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import "./listing.css";
import RefferalAgentListingCompleted from "./RefferalAgentListingCompleted";
import Step1 from "./ReferralListingSteps/Step1";
import Step2 from "./ReferralListingSteps/Step2";
import Step3 from "./ReferralListingSteps/Step3";
import Step4 from "./ReferralListingSteps/Step4";
import Step5 from "./ReferralListingSteps/Step5";

import { useForm } from "react-hook-form";
import heic2any from "heic2any";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LOGO, CLOSEICON } from "../../constants/imagepath";

// Referral Api function import from aoi services
import {
  referralGetEquipmentOptions,
  referralGetLicenseOptions,
  referralGetPleasureBoatOptions,
  referralGetSeaFoodOptions,
  referralMakeSellerRequest,
  referralObjectFiltersNew,
} from "../../api_services/api_calls/referralAgentListingApi";
import { LazyLoadImage } from "react-lazy-load-image-component";

const RefferalAgentListing = (props) => {
  const {
    register,
    trigger,
    clearErrors,
    setError,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm(); // using react useform hook values
  // states for store and update the component data
  const [step, setStep] = useState(0);
  const [userName, setUserName] = useState();
  const [listingCompleted, setListingCompleted] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [btnError, setBtnError] = useState("");
  const [boatAddress, setBoatAddress] = useState("");
  const [boatProvince, setBoatProvince] = useState("");
  const [boatCountry, setBoatCountry] = useState("");
  // const [boatPostalCode, setBoatPostalCode] = useState("");
  const [boatLatLng, setBoatLatLng] = useState("");
  const [imgloading, setImgLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [stepActive, setActiveStep] = useState(2);
  const [loader, setLoader] = useState(false);

  // state for store and update values of input fields
  const [state, setState] = useState({
    SellType: "",
    ItemType: "",
    boatOfficialNumber: "",
    boatName: "",
    boatRegNum: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNo: "",
    email: "",
    itemLocation: "",
    askingPrice: "",
    UploadLater: false,
  });

  // state for store and update values of input fields
  const [listingOptions, setListingOptions] = useState({
    constructionTypes: [],
    constructions: [],
    berths: [],
    enginetypes: [],
    enginemanufacturers: [],
    enginevolts: [],
    marineGearManufacturer: [],
    generalSpecies: [],
    brokerArea: [],
    brokerLicenseTypeGeneral: [],
    states: [],
    brokerPleasureClass: [],
    brokerPleasureCategory: [],
    brokerEquipmentCategory: [],
    brokerEquipmentProduct: [],
    brokerGeneralSpecies: [],
    brokerGeneralSpeciesSize: [],
  });

  function imageDisplay(e) {
    if (e.target.files && e.target.files.length > 0) {
      setErrorMsg(false);
      setImgLoading(true);
      var files = e.target.files;
      let image_Arr = [];
      const allowedFileTypes = [
        "jpg",
        "jpeg",
        "gif",
        "png",
        "heic",
        "mp4",
        "mpeg",
        "ogg",
        "webm",
      ];
      Array.from(files).forEach((file) => {
        var fileNameExt = file.name.substr(file.name.lastIndexOf(".") + 1);
        if (!allowedFileTypes.includes(fileNameExt)) {
          setErrorMsg(
            `File does not support. Files type must be ${allowedFileTypes.join(
              "/ "
            )}`
          );
          setImgLoading(false);
          return false;
        } else {
          if (fileNameExt === "heic") {
            var blob = file;
            heic2any({
              blob: blob,
              toType: "image/jpg",
            })
              .then(function (resultBlob) {
                image_Arr.push(resultBlob);
              })
              .catch(function (x) {
                console.log(x.code);
                console.log(x.message);
              });
          } else {
            image_Arr.push(file);
          }
        }
      });
      setTimeout(() => {
        setImgLoading(false);
        // setUploading(false);
        setSelectedImage([...selectedImage, ...image_Arr]);
      }, 3000);
    }
  }
  // handle to set all the addreses like boat address, provience.country etc.
  function handleAddress(data) {
    setBoatAddress(data.address);
    setBoatProvince(data.state_id);
    setBoatCountry(data.country_id);
    // setBoatPostalCode(data.postal_code);
    setBoatLatLng(data.lat_lng);
  }
  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = (e) => {
    var array = [...selectedImage]; // make a separate copy of the array
    var index = e.target.name;
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedImage(array);
    }
  };

  // this handle will used in to onChange of values of input fields
  function handleChange(evt) {
    // console.log('evt===', evt)
    const value = evt.target.value;
    if (typeof evt.target.value === "string" && value.trim().length === 0) {
      setError(evt.target.name, {
        type: "required",
        message: "This field is required.",
      });
    } else {
      setState({
        ...state,
        [evt.target.name]: value,
      });
    }
    focusOnElement(evt.target.id);
  }

  function handleChangeNormal(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    focusOnElement(evt.target.id);
  }

  function focusOnElement(id) {
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 5);
  }

  // This function is triggered for set the validations step by step
  function triggerValidation(action) {
    const fields_arr = [
      {},
      {},
      {
        fields: {
          Boat: ["boatName"],
          User: ["firstName", "lastName", "phoneNo", "email", "askingPrice"],
        },
      },
      {
        fields: {
          User: ["firstName", "lastName", "phoneNo", "email", "askingPrice"],
          License: ["boatName", "LicenseSpecies", "LicenseArea"],
        },
      },
    ];
    if (step === 0) {
      if (state.SellType === "") {
        setBtnError("Please select one of above");
        return false;
      } else {
        setBtnError("");
        setStep(step + 1);
      }
    }
    if (step === 1) {
      if (state.ItemType === "") {
        setBtnError("Please select one of above");
        return false;
      } else {
        setBtnError("");
        setStep(step + 1);
      }
    }
    if (step === 2) {
      if (state.ItemType === "Boat") {
        trigger(fields_arr[step].fields["Boat"]).then((valid) => {
          if (valid) setStep(step + 1);
        });
      } else {
        trigger(fields_arr[step].fields["User"]).then((valid) => {
          if (valid) setStep(step + 1);
        });
      }
    }
    if (step === 3) {
      if (state.ItemType === "Boat") {
        trigger(fields_arr[step].fields["User"]).then((valid) => {
          if (valid) setStep(step + 1);
        });
      } else {
        trigger(fields_arr[step].fields["License"]).then((valid) => {
          if (valid)
            if (action === "next") {
              setStep(step + 1);
            } else {
              submitListing();
            }
        });
      }
    }
    if (step === 4) {
      if (action === "final") {
        submitListing();
      }
      return true;
    }
  }

  // this handle will triggered on click of next button
  function setNextStep(event) {
    event.preventDefault();
    if (Object.keys(errors).length === 0) {
      triggerValidation("next");
    }
  }

  // this handle will triggered on click of back button
  function setPrevStep(e) {
    e.preventDefault();
    setBtnError("");
    setStep(step - 1);
    clearErrors();
  }

  function handleChangeClick(event) {
    props.onChange(false);
  }

  // handle for highlight the active button
  function handleButtonSelect(evt) {
    evt.className += "active";
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
    setStep(step + 1);
    setBtnError("");
    reset();
  }

  // handle for manage values of the input field on Change function
  function handleButtonInput(evt) {
    evt.preventDefault();
    evt.className += "active";
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  // this handle will triggered on submission of finish button and will check all the validations
  function submitSellerListing(e) {
    e.preventDefault();
    triggerValidation("final");
  }

  // this handle will set the data in states and updated with latest value
  function submitListing() {
    setSubmitting(true);
    var data = new FormData();
    data.append("selling_option", state.SellType);
    data.append("boat_location_address", boatAddress);
    data.append("boat_lat_lng", boatLatLng);
    data.append("boat_location_province", boatProvince);
    data.append("boat_location_country", boatCountry);
    if (state.ItemType === "Boat") {
      data.append("sell_type", "boat");
      data.append("vessel_official_number", state.boatOfficialNumber);
      data.append("boat_name", state.boatName);
      data.append("boat_registration_number", state.boatRegNum);
      data.append("boat_location_postal", state.boatPostalCode);
      for (let i = 0; i < selectedImage.length; i++) {
        data.append("files[]", selectedImage[i]);
      }
    }
    if (state.ItemType === "License") {
      data.append("sell_type", "license");
      data.append("boat_name", state.boatName);
      data.append("license_species", state.LicenseSpecies);
      data.append("license_area", state.LicenseArea);
    }
    if (state.ItemType === "Package") {
      data.append("sell_type", "package");
      data.append("boat_name", state.boatName);
      data.append("license_species", state.LicenseSpecies);
      data.append("license_area", state.LicenseArea);
    }
    if (state.ItemType === "Quota") {
      data.append("sell_type", "quota");
      data.append("boat_name", state.boatName);
      data.append("license_species", state.LicenseSpecies);
      data.append("license_area", state.LicenseArea);
    }
    //data.append('listing_type', );
    data.append("first_name", state.firstName);
    data.append("middle_name", state.middleName);
    data.append("last_name", state.lastName);
    data.append("phone", state.phoneNo);
    data.append("email", state.email);
    data.append("price", state.askingPrice);
    data.append("uploaded_by", "referral");
    data.append("upload_later", state.UploadLater);
    // axios(config)
    setLoader(true);
    referralMakeSellerRequest(data) // api call via api services for make seller request
      .then(function (response) {
        setLoader(true);

        if (response.data.status) {
          setListingCompleted(true);
        } else {
          // alert("Some error occured.");
          toast.error("Something went wrong please try again later");

          setSubmitting(false);
        }
        //handleChangeClick();
      })
      .catch(function (error) {
        console.log(error);
        setSubmitting(false);
      });
    // }
  }

  useEffect(() => {
    // handle for call get apis for all listins like object filters new ,license, pleasure, equipment,pleasure boat
    if (props.profileStatus) {
      setUserName(props.userData.profile.profile_data.first_name);
    } else {
      setUserName(props.userData.user.email);
    }
    referralObjectFiltersNew() // api implement via api services for object filters
      .then(function (response) {
        setListingOptions((listingOptions) => ({
          ...listingOptions,
          berths: response?.data?.data?.berths,
          ["constructionTypes"]: response?.data?.data?.constructiontypes,
          ["constructions"]: response?.data?.data?.constructions,
          ["enginemanufacturers"]: response?.data?.data?.enginemanufacturers,
          ["enginetypes"]: response?.data?.data?.enginetypes,
          ["enginevolts"]: response?.data?.data?.enginevolts,
          ["marineGearManufacturer"]:
            response?.data?.data?.marinegearmanufacturers,
          ["states"]: response?.data?.data?.states,
        }));
      })
      .catch(function (error) {
        console.log(error);
      });

    referralGetLicenseOptions() // api implement for license options via api services
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            ["generalSpecies"]: response1.data.data.generalSpecies,
            ["brokerArea"]: response1.data.data.brokerArea,
            ["brokerLicenseTypeGeneral"]:
              response1.data.data.brokerLicenseTypeGeneral,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    referralGetPleasureBoatOptions() // api implement for pleasure boat options via api services
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            ["brokerPleasureCategory"]:
              response1.data.data.brokerPleasureCategory,
            ["brokerPleasureClass"]: response1.data.data.brokerPleasureClass,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    referralGetEquipmentOptions() // api implement for get equipment options via api services
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            ["brokerEquipmentCategory"]: response1.data.data.brokerGearCategory,
            ["brokerEquipmentProduct"]: response1.data.data.brokerGearProduct,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    referralGetSeaFoodOptions() // api implement for get sea food options via api services
      .then(function (response1) {
        if (response1.data.status) {
          setListingOptions((listingOptions) => ({
            ...listingOptions,
            ["brokerGeneralSpecies"]: response1.data.data.brokerGeneralSpecies,
            ["brokerGeneralSpeciesSize"]:
              response1.data.data.brokerGeneralSpeciesSize,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <>
      <div id="popup1" className="overlay_2">
        <div className="popup1">
          <div className="popup1-header">
            <LazyLoadImage
              src={LOGO}
              alt="Logo"
              effect="blur"
              className="logoImg"
            />
          </div>

          <a
            className="close"
            onClick={handleChangeClick}
            href="javascript:void(0)"
          >
            <img src={CLOSEICON} alt="close icon" />
          </a>
          <section className="multi_step_form">
            <form id="msform">
              {(() => {
                return (
                  <ul id="progressbar">
                    {numbers.map((number) => (
                      <li className={stepActive >= number ? "active" : ""}></li>
                    ))}
                  </ul>
                );
              })()}
              <fieldset>
                <div className="conten">
                  {listingCompleted ? (
                    <RefferalAgentListingCompleted
                      stateData={state}
                      listingOptions={listingOptions}
                      boatAddress={boatAddress}
                    />
                  ) : step === 0 ? (
                    <Step1
                      userName={userName}
                      values={state}
                      getValues={getValues}
                      handleFormData={handleButtonSelect}
                      error={btnError}
                    />
                  ) : step === 1 ? (
                    <Step2
                      values={state}
                      handleFormData={handleButtonSelect}
                      getValues={getValues}
                      error={btnError}
                    />
                  ) : step === 2 ? (
                    <Step3
                      values={state}
                      handleFormData={handleChange}
                      getValues={getValues}
                      handleChangeNormal={handleChangeNormal}
                      clearErrors={clearErrors}
                      register={register}
                      handleAddress={handleAddress}
                      error={errors}
                    />
                  ) : step === 3 ? (
                    <Step4
                      values={state}
                      handleFormData={handleChange}
                      getValues={getValues}
                      setValue={setValue}
                      control={control}
                      clearErrors={clearErrors}
                      handleChangeNormal={handleChangeNormal}
                      register={register}
                      handleAddress={handleAddress}
                      error={errors}
                      selectValues={listingOptions}
                    />
                  ) : state.ItemType === "Boat" && step === 4 ? (
                    <Step5
                      values={state}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      handleImageDisplay={imageDisplay}
                      handleChangeNormal={handleChangeNormal}
                      handleButtonInput={handleButtonInput}
                      errorMsg={errorMsg}
                      selectedImage={selectedImage}
                      styles={styles}
                      removeSelectedImage={removeSelectedImage}
                      imgloader={imgloading}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {listingCompleted ? (
                  <></>
                ) : (
                  <>
                    {step > 0 ? (
                      <button
                        className="action-button previous previous_button"
                        disabled={submitting ? true : false}
                        onClick={setPrevStep}
                      >
                        Back
                      </button>
                    ) : (
                      ""
                    )}

                    {state.ItemType !== "Boat" && step === 3 ? (
                      ""
                    ) : step < 4 ? (
                      <button
                        className="next action-button"
                        disabled={submitting ? true : false}
                        onClick={setNextStep}
                      >
                        Next
                      </button>
                    ) : (
                      ""
                    )}

                    {loader ? (
                      <div className="loader"></div>
                    ) : state.ItemType === "Boat" && step === 4 ? (
                      <button
                        className="next action-button finish_btn"
                        disabled={submitting ? true : false}
                        onClick={submitSellerListing}
                      >
                        Finish
                      </button>
                    ) : (state.ItemType === "License" ||
                      state.ItemType === "Package" ||
                      state.ItemType === "Seafood" ||
                      state.ItemType === "Quota") &&
                      step === 3 ? (
                      <button
                        className="next action-button finish_btn"
                        disabled={submitting ? true : false}
                        onClick={submitSellerListing}
                      >
                        Finish
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </fieldset>
            </form>
          </section>
        </div>
      </div>
    </>
  );
};

export default RefferalAgentListing;

// Just some styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 50,
  },
  preview: {
    marginTop: 50,
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%", maxHeight: 320 },
  delete: {
    cursor: "pointer",
    background: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "27px",
    position: "absolute",
    right: "18px",
  },
  displayImage: {
    border: "1px solid grey",
    margin: "5px 2px 2px 2px",
  },
  imgcontainer: {
    borderTop: "2px solid grey",
  },
};


import React from "react";
const Step9 = ({ userName, handleFormData, values, register, error, clearErrors, getValues, trigger }) => {

  return (
    <>
      <p className="good_day_para mt-2">Do You Already Have Financial Inplace?</p>
      <div className="button-wrap">
        <input className="hidden radio-label" type="radio" name="FinanceInplace" id="yes2-button" value="Yes" onClick={handleFormData} checked={values.FinanceInplace === 'Yes' ? 'checked' : ''} />
        <label className="button-label_2" for="yes2-button">
          <h1>Yes</h1>
        </label>
        <input className="hidden radio-label" type="radio" name="FinanceInplace" value="No" id="no2-button" onClick={handleFormData} checked={values.FinanceInplace === 'No' ? 'checked' : ''} />
        <label className="button-label_2" for="no2-button">
          <h1>No</h1>
        </label>
      </div>
    </>
  );
}

export default Step9;

import React from "react";

const Step7 = ({
  handleFormData,
  values,
  register,
  error,
  selectValues,
  getValues,
  clearErrors,
  trigger,
}) => {
  var current_year = new Date().getFullYear(); // variable for get current year

  return (
    <>
      <p className="good_day_para">
        Please Provide The Basic Vessel Built Year
      </p>
      <span className="popup_input_label">
        What Is The Minimum Year Built:<span className="required-field"></span>
      </span>
      <input
        className="text-box_2"
        type="text"
        placeholder="Ex : 1920"
        name="MinYearBuilt"
        {...register("MinYearBuilt", {
          required: "This field is required.",
          pattern: {
            value: /^([1-9][0-9]{3})+$/,
            message: "Please enter valid year.",
          },

          validate: {
            maxNumber: (value) => {
              if (parseFloat(value) < 1920 || value > current_year) {
                return "Year should be in range 1920 to current year";
              } else if (value >= getValues("MaxYearBuilt")) {
                return "Minimum year should not be greater than maximum year";
              }
            },
          },
        })}
        onChange={(e) => {
          clearErrors("MinYearBuilt");
          handleFormData(e);
        }}
      />
      {error?.MinYearBuilt && (
        <span className="err-msg position-relative" style={{ top: "-10px" }}>{error?.MinYearBuilt?.message}</span>
      )}
      <span className="popup_input_label">
        What Is The Maximum Year Built:<span className="required-field"></span>
      </span>
      <input
        className="text-box_2"
        type="text"
        placeholder="Ex : 1921"
        name="MaxYearBuilt"
        {...register("MaxYearBuilt", {
          required: "This field is required.",
          pattern: {
            value: /^([1-9][0-9]{3})+$/,
            message: "Please enter valid year.",
          },

          validate: {
            maxNumber: (value) => {
              if (parseFloat(value) <= 1920 || value > current_year) {
                return "Year should be in range 1920 to current year";
              }
              trigger("MinYearBuilt");
            },
          },
        })}
        onChange={(e) => {
          clearErrors("MaxYearBuilt");
          handleFormData(e);
        }}
      />
      {error?.MaxYearBuilt && (
        <span className="err-msg position-relative" style={{ top: "-10px" }}>{error?.MaxYearBuilt?.message}</span>
      )}
    </>
  );
};

export default Step7;

import React from "react";
// import MyGoogleMap from '../../GoogleMap/MyGoogleMap';
import { useDispatch, useSelector } from "react-redux";
import { referralListingActions, referralListingSelector } from "../../../store/referralListingSlice";
import ReferralMyGoogleMap from "../../GoogleMap/referralMyGoogleMap";

const SellerDetail = ({ handleFormData, values, register, handleAddress, error, clearErrors }) => {
    const { location } = useSelector(referralListingSelector);
    const dispatch = useDispatch();

    //handle for set the address,state,country etc. fields on onChange of input values
    const changeAddress = (childData) => {
        if (handleAddress !== undefined) {
            handleAddress({ address: childData.address, state_id: childData.state_id, country_id: childData.country_id, postal_code: childData.postal_code, lat_lng: childData.lat_lng });
            console.log('childData', childData)
            dispatch(referralListingActions.setSelectedLocation(childData));
        }
    }





    return (
        <>
            <p className="good_day_para">Please fill the following details</p>
            <form>
                <div className="row">
                    <div className="col-md-12">
                        <span className="popup_input_label">First Name<span className="required-field"></span></span>
                    </div>
                    <div className="col-md-12">
                        <input type="text" name="firstName" defaultValue={values.firstName} className="popup_input" id="firstName"
                            {...register("firstName", {
                                required: "This field is required",
                                pattern: {
                                    value: /^[^\s][a-zA-Z\s]+[^\s]$/,
                                    message: "Blank space not allowed in the beginning"
                                }
                            })}
                            onChange={(e) => {
                                clearErrors("firstName")
                                handleFormData(e)
                            }}

                        />
                        {error.firstName && <span className="err-msg">{error.firstName.message}</span>}
                    </div>
                    <div className="col-md-12">
                        <span className="popup_input_label">Last Name<span className="required-field"></span></span>
                    </div>
                    <div className="col-md-12">
                        <input type="text" className="popup_input" name="lastName" defaultValue={values.lastName} id="lastName"  {...register("lastName", { required: "This field is required." })}
                            onChange={(e) => {
                                clearErrors("lastName")
                                handleFormData(e)
                            }}
                        />
                        {error.lastName && <span className="err-msg">{error.lastName.message}</span>}
                    </div>
                    <div className="col-md-12">
                        <span className="popup_input_label">Phone Number<span className="required-field"></span></span>
                    </div>
                    <div className="col-md-12">
                        <input type="number" className="popup_input" name="phoneNo" defaultValue={values.phoneNo} id="phoneNo"  {...register("phoneNo", { required: "This field is required." })}
                            onChange={(e) => {
                                clearErrors("phoneNo")
                                handleFormData(e)
                            }}
                        />
                        {error.phoneNo && <span className="err-msg">{error.phoneNo.message}</span>}
                    </div>
                    <div className="col-md-12">
                        <span className="popup_input_label">Email<span className="required-field"></span></span>
                    </div>
                    <div className="col-md-12">
                        <input type="text" className="popup_input" name="email" defaultValue={values.email} id="email"  {...register("email", { required: "This field is required." })}
                            onChange={(e) => {
                                clearErrors("email")
                                handleFormData(e)
                            }}
                        />
                        {error.email && <span className="err-msg">{error.email.message}</span>}
                    </div>
                    <div className="col-md-12">
                        <span className="popup_input_label">Asking Price<span className="required-field"></span></span>
                    </div>
                    <div className="col-md-12">
                        <input type="number" className="popup_input" name="askingPrice" value={values.askingPrice} id="askingPrice"
                            {...register("askingPrice",
                                {
                                    required: "This field is required.",
                                    valueAsNumber: true,
                                    validate: {
                                        required: (value) => {
                                            if (parseFloat(value) <= 100) {
                                                return "Asking price should not be less than $100";
                                            }
                                        },
                                    },
                                }
                            )}
                            onChange={(e) => {
                                clearErrors("askingPrice")
                                handleFormData(e)
                            }}
                        />
                        {error.askingPrice && <span className="err-msg">{error.askingPrice.message}</span>}
                    </div>
                    <div className="col-md-12">
                        <span className="popup_input_label">Location</span>
                    </div>
                    <div className="col-md-12">
                        <ReferralMyGoogleMap parentCallback={changeAddress} vesselLocation={location} />

                    </div>
                </div>
            </form>

        </>
    );
}

export default SellerDetail;
import { Url } from "../api_settings/api_constants";
import { wrappedFetch, wrappedGet } from "../api_settings/urlFunctions";





//update license listing 
export const getRequestType = () => {
    return wrappedGet(Url.GET_REQUEST_TYPE, "GET");
};

//get request type
export const getRequestTypes = (id) => {
    return wrappedGet(Url.GET_REQUEST_TYPES + id, "GET");
};

//get request by id 
export const getRequestById = (id) => {
    return wrappedGet(Url.GET_REQUEST_BY_ID + id, "GET");
};

//request edit object
export const requestEditObject = (id) => {
    return wrappedGet(Url.REQUEST_EDIT_OBJECT + id, "GET");
};

//buyer object filters new
export const buyerObjectFiltersNew = () => {
    return wrappedGet(Url.BUYER_OBJECT_FILTERS_NEW, "GET");
};

//buyer license object options
export const buyerLicenseObject = () => {
    return wrappedGet(Url.BUYER_GET_LICENSE_OPTION, "GET");
};

//buyer get equipment options
export const buyerGetEquipmentOptions = () => {
    return wrappedGet(Url.BUYER_GET_EQUIPMENT_OPTION, "GET");
};

//buyer get ea food options
export const buyerGetSeaFoodOptions = () => {
    return wrappedGet(Url.BUYER_GET_SEAFOOD_OPTION, "GET");
};


//buyer request update
export const buyerRequestUpdate = (data, id) => {
    return wrappedFetch(Url.BUYER_REQUEST_UPDATE_OBJECT + id, "POST", data);
};


//buyer get ea food options
export const buyerRequestDelete = (id) => {
    return wrappedGet(Url.BUYER_REQUEST_DELETE_LISTING + id, "GET");
};

export const buyerRequestObjectDelete = (id) => {
    return wrappedGet(Url.BUYER_REQUEST_DELETE_OBJECT + id, "GET");
}

export const buyerRequestUpdateSeller = (data, id) => {
    return wrappedFetch(Url.BUYER_REQUEST_UPDATE + id, "POST", data);
};


//buyer get pleasureboat options api
export const buyerGetPleasureBoatOptions = () => {
    return wrappedGet(Url.BUYER_GET_PLEASURE_BOAT_OPTION, "GET");
};
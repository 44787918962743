import React from "react";
import { Controller } from "react-hook-form";
// import SearchSelect from "../../selectSearch/SearchSelect";
import SearchSelectCreate from "../../selectSearch/SearchSelectCreate";
const Step8 = ({
  handleFormData,
  values,
  clearErrors,
  handleChangeNormal,
  handleButtonInput,
  selectValues,
  register,
  error,
  control,
  setValue,
  getValues,
}) => {
  return (
    <>
      <p className="good_day_para">Basic Mechanical Information Step 2/2:</p>
      <span className="popup_input_label">
        What is the type of reduction:<span className="required-field"></span>
      </span>
      <Controller
        name="TypeOfReduction"
        control={control}
        defaultValue=""
        rules={{ required: "This field is required" }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <SearchSelectCreate
            defaultValue={
              selectValues.marineGearManufacturer.find(
                (e) => e.id === getValues("TypeOfReduction")
              )
                ? {
                  value: selectValues.marineGearManufacturer.find(
                    (e) => e.id === getValues("TypeOfReduction")
                  ).id,
                  label: selectValues.marineGearManufacturer.find(
                    (e) => e.id === getValues("TypeOfReduction")
                  )?.name,
                }
                : {
                  value: getValues("TypeOfReduction"),
                  label: getValues("TypeOfReduction"),
                }
            }
            onChange={(data, action) => {
              setValue("TypeOfReduction", data.value);
              handleFormData({
                target: {
                  value: data.value,
                  name: "TypeOfReduction",
                },
              });

              clearErrors("TypeOfReduction");
            }}
            options={selectValues?.marineGearManufacturer?.map(
              (marinegear, index) => {
                return { value: marinegear.id, label: marinegear.name };
              }
            )}
          />
        )}
      />
      {error.TypeOfReduction && (
        <span className="err-msg">{error.TypeOfReduction.message}</span>
      )}
      <span className="popup_input_label">Reduction Ratio:</span>
      <input
        type="text"
        onKeyPress={(event) => {
          if (event.charCode < 48) {
            event.preventDefault();
          }
        }}
        min="0"
        className="text-box_2"
        id="ReductionRatio"
        onChange={handleChangeNormal}
        defaultValue={values.ReductionRatio}
        name="ReductionRatio"
      />
      <span className="popup_input_label">Type Of Shaft Material:</span>
      <input
        className="text-box_2"
        type="text"
        placeholder="Ex: Stainless steels"
        onChange={handleChangeNormal}
        defaultValue={values.TypeOfShaftMetarial}
        name="TypeOfShaftMetarial"
      />
      <span className="popup_input_label">Size Of Shaft</span>
      <input
        className="text-box_2"
        type="text"
        placeholder="Ex: 30-36"
        onChange={handleChangeNormal}
        defaultValue={values.SizeOfShaft}
        name="SizeOfShaft"
      />
      <span className="popup_input_label">Has The Engine Been Rebuilt:</span>
      <div>
        <input
          className="hidden radio-label"
          type="radio"
          name="EngineRebuilt"
          value="YES"
          id="yes-button-2"
          checked={values.EngineRebuilt === "YES" ? "checked" : ""}
          onClick={handleButtonInput}
        />
        <label className="button-label" for="yes-button-2">
          <h1>YES</h1>
        </label>
        <input
          className="hidden radio-label"
          type="radio"
          name="EngineRebuilt"
          value="No"
          id="no-button-2"
          checked={values.EngineRebuilt === "No" ? "checked" : ""}
          onClick={handleButtonInput}
        />
        <label className="button-label" for="no-button-2">
          <h1>No</h1>
        </label>
      </div>
    </>
  );
};

export default Step8;

import { Url } from "../api_settings/api_constants";
import { wrappedFetch } from "../api_settings/urlFunctions";






//buyer request update
export const buyButtonSubmit = (data) => {
    return wrappedFetch(Url.BUY_BUTTON_REQUEST_STORE, "POST", data);
};
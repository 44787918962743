import { createSlice } from "@reduxjs/toolkit";


const initialState = { // "Initial state for set static lat lng"
    location: { lat: 45.1314415, lng: -66.353824, address: null },
};

const referralListingSlice = createSlice({ // Listing slice for set all listings
    name: 'listing',
    initialState,
    reducers: {
        setSelectedLocation: (state, action) => { // reducer for update lat lng
            var lat_lng = action.payload.lat_lng.split("_")
            state.location = { lat: lat_lng[0], lng: lat_lng[1], address: action.payload.address }
        }
        , reset: (state, action) => { // reducer for update lat lng
            state = initialState;
            return state;
        }
    },
});

export const referralListingActions = referralListingSlice.actions;

export const referralListingSelector = (state) => state.listing;

export default referralListingSlice.reducer;

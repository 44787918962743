import React from 'react';
import { useState, useEffect } from 'react';

const Step6 = ({ handleFormData, values, error, register, clearErrors }) => {
  // console.log('errrs', error)

  // state manage for change palceholder values
  const [placeHoldervalue, setPlaceholdervalue] = useState('FT-IN')
  const [placeHoldervalue1, setPlaceholdervalue1] = useState('FT-IN')
  const [placeHoldervalue2, setPlaceholdervalue2] = useState('FT-IN')


  const [checklength, setCheckLength] = useState('')




  // useEffect for compare min length and max length
  useEffect(() => {
    const minTotalCm = values?.VesselLengthFoot * 30.48 + values?.VesselLengthInch * 2.54;
    const maxTotalCm = values?.VesselWidthFoot * 30.48 + values?.VesselWidthInch * 2.54;

    if (maxTotalCm >= minTotalCm) {
      return setCheckLength('Width of vessel should not be greater or equal to length of vessel');
    } else {
      return setCheckLength('');
    }
  }, [values?.VesselLengthFoot, values?.VesselLengthInch, values?.VesselWidthFoot, values?.VesselWidthInch])

  return (
    <>
      <p className="good_day_para">Please Provide The Basic Vessel Measurements</p>
      <span className="popup_input_label">Length Of Vessel:<span className="required-field"></span></span>
      <div className="measurements">
        <label for="input1" className="input1">
          <input id="input1" type="text" placeholder="Ex: 80" name="VesselLengthFoot" defaultValue={values.VesselLengthFoot}  {...register("VesselLengthFoot", {
            required: "This field is required.",
            validate: {
              required:
                (value) => { if (parseFloat(value) <= 0) return 'Length should be greater than zero.' }
            }
          })}
            onChange={(e) => {
              clearErrors("VesselLengthFoot")
              handleFormData(e)
            }} /><span>Feets</span>
        </label>
        <label for="input1" className="input1">
          <input id="input1" type="text" placeholder="Ex: 10" name="VesselLengthInch" defaultValue={values.VesselLengthInch}   {...register("VesselLengthInch",
            {
              required: "This field is required.",
              validate: {
                maxNumber: (value) => {
                  if (placeHoldervalue === 'FT-IN') {
                    if (parseFloat(value) > 11) {
                      return "Inch should not be greater than 11"
                    }
                  } else {
                    if (parseFloat(value) > 99) {
                      return "Cm should not be greater than 99"
                    }
                  }
                }
              }
            }
          )}
            onChange={(e) => {
              clearErrors("VesselLengthInch")
              handleFormData(e)
            }} /><span>Inches</span>
        </label>
        <div className="select-dropdown">
          <div className="dropdown">
            <select className="custom-select mr-sm-2 col-md-3 col-sm-4 col-3 mb-2 padding0 " id="inlineFormCustomSelect" onChange={(e) => { handleFormData(e); setPlaceholdervalue(e.target.value) }} defaultValue={values.VesselLengthUnit} name="VesselLengthUnit">
              <option value='FT-IN' selected="selected" >Feet-Inches</option>
              <option value='MT-CM'>Meter-Cm</option>
            </select>
          </div>
        </div>


      </div>
      {
        (error.VesselLengthFoot || error.VesselLengthInch) && <span className="err-msg">{error.VesselLengthFoot ? error.VesselLengthFoot.message : error.VesselLengthInch.message}</span>
      }
      <span className="popup_input_label">Please Enter The Length Of Extension:</span>
      <div className="measurements">
        <label for="input1" className="input1">
          <input id="input1" type="text" placeholder="Ex: 60" name="VesselExtensionFoot" onKeyPress={(event) => { if (event.charCode < 48) { event.preventDefault(); } }} min="1" defaultValue={values.VesselExtensionFoot}  {...register("VesselExtensionFoot")}

            onChange={handleFormData} /><span>Feets</span>
        </label>
        <label for="input1" className="input1">
          <input id="input1" type="text" placeholder="Ex: 07" name="VesselExtensionInch"  {...register("VesselExtensionInch",
            {
              validate: {
                maxNumber: (value) => {
                  if (placeHoldervalue1 === 'FT-IN') {
                    if (parseFloat(value) > 11) {
                      return "Inch should not be greater than 11"
                    }
                  } else {
                    if (parseFloat(value) > 99) {
                      return "Cm should not be greater than 99"
                    }
                  }
                }
              }
            }
          )}
            onChange={(e) => {
              clearErrors("VesselExtensionInch")
              handleFormData(e)
            }}
          /><span>Inches</span>
        </label>
        <div className="select-dropdown">
          <div className="dropdown">
            <select className="custom-select mr-sm-2 col-md-3 col-3 mb-2 padding0" id="inlineFormCustomSelect" onChange={(e) => { handleFormData(e); setPlaceholdervalue1(e.target.value) }} value={values.VesselExtensionUnit} name="VesselExtensionUnit">
              <option value='FT-IN'>Feet-Inches</option>
              <option value='MT-CM'>Meter-Cm</option>
            </select>
          </div>
        </div>


      </div>
      {(error.VesselExtensionFoot || error.VesselExtensionInch) && <span className="err-msg">{error.VesselExtensionFoot ? error.VesselExtensionFoot.message : error.VesselExtensionInch.message}</span>}
      <span className="popup_input_label">Width Of The Vessel:<span className="required-field"></span></span>
      <div className="measurements">
        <label for="input1" className="input1">
          <input id="input1" type="text" placeholder="Ex: 15" name="VesselWidthFoot" defaultValue={values.VesselWidthFoot}  {...register("VesselWidthFoot",
            {
              required: "This field is required.",
              validate: {
                required: (value) => {
                  if (parseFloat(value) <= 0) {
                    return 'Min length should be greater than zero.'
                  }
                  if (checklength?.length !== 0) {
                    return checklength;
                  }
                }
              },
            })}
            onChange={(e) => {
              clearErrors("VesselWidthFoot")
              handleFormData(e)
            }} /><span>Feets</span>
        </label>
        <label for="input1" className="input1">
          <input id="input1" type="text" placeholder="Ex: 10" name="VesselWidthInch" defaultValue={values.VesselWidthInch}
            {...register("VesselWidthInch",
              {
                required: "This field is required.", validate: {
                  maxNumber: (value) => {
                    if (placeHoldervalue2 === 'FT-IN') {
                      if (parseFloat(value) > 11) {
                        return "Inch should not be greater than 11"
                      }
                    } else {
                      if (parseFloat(value) > 99) {
                        return "Cm should not be greater than 99 "
                      }
                    }
                  }
                }
              }
            )}
            onChange={(e) => {
              clearErrors("VesselWidthInch")
              handleFormData(e)
            }} /><span>Inches</span>
        </label>
        <div className="select-dropdown">
          <div className="dropdown">
            <select className="custom-select mr-sm-2 col-md-3 col-3 mb-2 padding0" id="inlineFormCustomSelect" onChange={(e) => { handleFormData(e); setPlaceholdervalue2(e.target.value) }} value={values.VesselWidthUnit} name="VesselWidthUnit">
              <option value='FT-IN'>Feet-Inches</option>
              <option value='MT-CM'>Meter-Cm</option>
            </select>
          </div>
        </div>
      </div>
      {((error.VesselWidthFoot || error.VesselWidthInch) && <span className="err-msg">{error.VesselWidthFoot ? error.VesselWidthFoot.message : error.VesselWidthInch.message}</span>)}
    </>
  );
}

export default Step6;

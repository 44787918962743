import { Url } from "../api_settings/api_constants";
import { wrappedFetch, wrappedGet } from "../api_settings/urlFunctions";




//refferal Request Malk seller request
export const referralRequestMakeSellerRequest = (data) => {
    return wrappedFetch(Url.REFERRAL_REQUEST_MAKE_SELLER_REQUEST, "POST", data);
};

//referral Request object filters new
export const referralRequestObjectFiltersNew = () => {
    return wrappedGet(Url.REFERRAL_REQUEST_OBJECT_FILTERS_NEW, "GET");
};




//referral Request license options
export const referralRequestLicenseOption = () => {
    return wrappedGet(Url.REFERRAL_REQUEST_GET_LICENSE_OPTION, "GET");
};

//referral Request get equipment options
export const referralRequestGetEquipmentOption = () => {
    return wrappedGet(Url.REFERRAL_REQUEST_GET_EQUIPMENT_OPTION, "GET");
};


//referral Request get sea food 
export const referralRequestGetSeaFoodOption = () => {
    return wrappedGet(Url.REFERRAL_REQUEST__GET_SEAFOOD_OPTION, "GET");
};






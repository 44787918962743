import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faVideo } from '@fortawesome/free-solid-svg-icons'
import { UPLOADICON } from "../../../constants/imagepath";
const allowExtensions = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', '.heic', 'video/mp4', 'video/mpeg', 'video/ogg', 'video/webm',];
// const allowedFileTypes = ["jpg", "jpeg", "gif", "png", "heic", "mp4", "mpeg", "ogg", "webm"]
const Step10 = ({ handleImageDisplay, handleButtonInput, errorMsg, values, selectedImage, removeSelectedImage, styles, imgloader }) => {

  return (
    <>
      <p className="good_day_para mt-2">Please Upload The Images & Video</p>
      <div className="upload__box">
        <div className="upload__btn-box">
          <img src={UPLOADICON} alt='upload' />
          <p className="click_here_to_upload_images_text">Click Here To Upload Images And Video</p>
          <label className="upload__btn">
            <p>Upload</p>
            <input type="file" multiple data-max_length="20" className="upload__inputfile" accept={allowExtensions} onChange={handleImageDisplay} />
          </label>
        </div>
        {errorMsg && <div className="alert alert-danger mt-3">{errorMsg}</div>}
        <div className="upload__img-wrap">
          {imgloader && (<div className="img-loader text-center"><FontAwesomeIcon icon={faSpinner} /> <h2>Loading..</h2> </div>)}
          {!imgloader && selectedImage && (
            selectedImage.map((imageSep, index) => (
              <div className='col-md-6' >
                <div style={styles.displayImage}>
                  {imageSep.type === "video/mp4" ? <FontAwesomeIcon icon={faVideo} className="video_icon" /> :
                    <img
                      src={URL.createObjectURL(imageSep)} style={styles.image}
                      alt="Thumb"
                    />}
                  <button onClick={removeSelectedImage} name={index} style={styles.delete}>
                    X
                  </button>
                </div>
              </div>
            ))
          )}

        </div>
      </div>
      <label className="skip_for_now_text" for="upload-later">Skip For Now</label>
      <input className="hidden radio-label" type="radio" name="UploadLater" value="true" id="upload-later" onClick={handleButtonInput} />
    </>
  );
}

export default Step10;

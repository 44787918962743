import React from "react";

const Step5 = ({
  handleFormData,
  values,
  register,
  error,
  clearErrors,
  getValues,
  trigger
}) => {
  console.log('errors', error)

  return (
    <>
      <p className="good_day_para">Please Provide The Basic Vessel Tonnage</p>
      <span className="popup_input_label">
        What Is The Minimum Gross Tonnage:
      </span>
      <input
        className="text-box_2"
        type="number"
        placeholder="Ex : 1"
        name="MinGrossTonnage"
        defaultValue={values?.MinGrossTonnage}
        {...register("MinGrossTonnage", {
          valueAsNumber: true,
          validate: {
            required: (value) => {
              if (parseFloat(value) >= parseFloat(getValues("MaxGrossTonnage")) || isNaN(parseFloat(getValues("MaxGrossTonnage")))) {
                return "Minimum gross should not be greater or equal to maximum gross";
              }
              trigger("MaxGrossTonnage");
            },
          },
          // validate: {
          //   required: (value) => {
          //     if (parseFloat(value) <= 0) {
          //       return "Minimum gross should be greater than zero.";
          //     }
          //     // } else if (value >= getValues("MaxGrossTonnage")) {
          //     //   return "Minimum gross should not be greater or equal to maximum gross";
          //     // }
          //   },
          // },
        })}
        onChange={(e) => {
          clearErrors("MinGrossTonnage");
          handleFormData(e);
        }}
      />
      <span className="err-msg position-relative" style={{ top: "-10px" }}>{error?.MinGrossTonnage?.message}</span>
      <span className="popup_input_label">
        What Is The Maximum Gross Tonnage:
      </span>
      <input
        className="text-box_2"
        type="number"
        placeholder="Ex : 3"
        name="MaxGrossTonnage"
        defaultValue={values.MaxGrossTonnage}
        {...register("MaxGrossTonnage", {
          // required: "This field is required.",
          valueAsNumber: true,
          validate: {
            required: (value) => {
              if (parseFloat(value) <= 0) {
                return "Maximum gross should be greater than zero.";
              }
            },
          },
        })}
        onChange={(e) => {
          clearErrors("MaxGrossTonnage");
          handleFormData(e);
        }}
      ></input>
      <span className="err-msg position-relative" style={{ top: "-10px" }}>{error?.MaxGrossTonnage?.message}</span>
    </>
  );
};

export default Step5;

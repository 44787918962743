import { Link } from "react-router-dom";
import Polygon from "../../assets/img/Polygon 5.png";

const Footer = ({ChangeCle}) => {
  return (
    <>
      <footer className={ ChangeCle=ChangeCle ? "footer  ct_footer_white" : "footer"}>
        {/* <img src={Polygon} /> */}
        <div className="footer_bottom">
          <nav class="navbar navbar-expand-lg navbar-light bg-light  justify-content-center">
            <ul class="navbar-nav">
              <li class="nav-item">
                <Link to={"/terms-of-use"} className="nav-link">Terms  Conditions</Link>
              </li>
              <li class="nav-item">
                <Link to={"/privacy-policy"} className="nav-link">Privacy Policy</Link>
              </li>

            </ul>
          </nav>
        </div>
      </footer>
    </>
  );
};

export default Footer;

import React from "react";
import Select from "react-select";
// import { colors } from "@/data/colors";
// import { getColorIndex } from "@/utils/colorHandle";

const SearchSelect = (props) => {
  const { options, placeholder, multi, defaultValue } = props;
  const getValuesData = (value, action) => {
    if (value.length === 0) {
      props.onChange();
    } else {
      props.onChange(value);
    }
  };

  return (
    <Select
      isMulti={multi ? true : false}
      options={options}
      onChange={(value) => getValuesData(value)}
      placeholder={placeholder}
      defaultValue={typeof defaultValue === "object" ? defaultValue : ""}
      value={defaultValue || null}
    //value={options.find((c) => c.value === testVal)}

    // styles={customSelect ? colourStyles : ""}
    />
  );
};

export default SearchSelect;

import React from 'react';
import BuyerDetail from './BuyerDetail';

const Step5 = ({ handleFormData, values, register, error, clearErrors, handleAddress }) => {
    return (
        <>
            {(values.ItemType === 'Boat')
                ? <BuyerDetail handleFormData={handleFormData} values={values} register={register} error={error} clearErrors={clearErrors} handleAddress={handleAddress} /> :
                ''}
        </>
    );
}

export default Step5;

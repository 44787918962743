/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, lazy } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/userSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "../../assets/css/bootstrap.css";
import "../../assets/css/responsive.css";
import "../../assets/css/style.css";
import { SENDICON } from "../../constants/imagepath";
import AddUserHeader from "../AddUserDetails/AddUserHeader";
import MultiStepProgressBar from "../../components/MultiStepProgressBar";
import {
    chatProfile,
    profileComplete,
} from "../../api_services/api_calls/authApi";

const MyGoogleMap = lazy(() =>
    import("../../components/GoogleMap/MyGoogleMap")
);

function SiteOverview(props) {
    const [chatInput, setChatInput] = useState("");
    const [questionNumber, setQuestionNumber] = useState("-1");

    const [intent, setIntent] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dob, setDob] = useState("");
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState("");
    const [pincode, setPincode] = useState("");
    const [lat_lng, setLatLng] = useState("");
    const [companyName, setCompanyName] = useState("");
    const ref = useRef();
    const [address, setAddress] = useState("");
    const [isFinished, setFinished] = useState("");
    const { userData } = useSelector(userSelector);
    const [step, setStep] = useState(0);
    const [errMsg, setErrMsg] = useState("");

    const changeAddress = (childData) => {
        console.log("childData", childData);
        if (childData.postal_code === undefined) {
            alert("No Postal Code available");
            return false;
        }
        setAddress(childData.address);
        setPincode(childData.postal_code);
        setLatLng(childData.lat_lng);
        setStateId(childData.state_id);
        setCountryId(childData.country_id);
        if (intent === "pincode") {
            document.getElementsByName("chat-input")[0].value = childData.postal_code;
        }
    };

    /******* Calling Dynamic URL ********/
    const apiUrl = process.env.REACT_APP_API_KEY;

    //var //chatArray = [];

    useEffect(() => {
        setEmail(userData.data.user.email);
        setPhone(userData.data.user.mobile);

        getChatDataResponse();
        // eslint-disable-next-line
    }, []);

    const getChatDataResponse = (skipToNext = "") => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("question_number", skipToNext ? skipToNext : questionNumber);
        data.append("answer", "");

        // var config = {
        //   method: "post",
        //   url: apiUrl + "chat-profile",
        //   headers: {
        //     Accept: "application/json",
        //     Authorization: "Bearer " + localStorage.getItem("token"),
        //   },
        //   data: data,
        // };

        // axios(config)

        chatProfile(data)
            .then(function (response) {
                if (response.data.status) {
                    var targetDiv = document.getElementById("chat-text");
                    var botChatLeftDiv = document.createElement("div");
                    var botChatLeftClass = document.createAttribute("class");
                    var botChatLeftInnerClass = document.createAttribute("class");
                    var botChatLeftImgClass = document.createAttribute("class");
                    var botChatLeftInnerDiv = document.createElement("div");
                    var botChatLeftInnerDiv1 = document.createElement("div");
                    var botChatTextDiv = document.createElement("div");
                    var botChatTextClass = document.createAttribute("class");
                    botChatLeftClass.value = "msg left-msg";
                    botChatLeftInnerClass.value = "msg-bubble";
                    botChatLeftImgClass.value = "msg-img";
                    botChatTextClass.value = "msg-text";
                    botChatLeftDiv.setAttributeNode(botChatLeftClass);
                    botChatLeftInnerDiv1.setAttributeNode(botChatLeftImgClass);
                    botChatLeftInnerDiv.setAttributeNode(botChatLeftInnerClass);
                    var botChatText = document.createTextNode(
                        response.data.question.question
                    );
                    botChatTextDiv.setAttributeNode(botChatTextClass);
                    botChatTextDiv.appendChild(botChatText);
                    botChatLeftInnerDiv.appendChild(botChatTextDiv);
                    botChatLeftDiv.appendChild(botChatLeftInnerDiv1);
                    botChatLeftDiv.appendChild(botChatLeftInnerDiv);
                    targetDiv.appendChild(botChatLeftDiv);
                    setQuestionNumber(response.data.question_number);
                    setIntent(response.data.question.intent);
                    if (skipToNext) {
                        var skip_element = document.getElementById("skip_to_next");
                        skip_element.remove();
                    }
                    //boatChatLeftInnerDiv2.remove();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    useEffect(() => {
        if (isFinished) {
            var FormData = require("form-data");
            var data = new FormData();
            data.append("first_name", firstName);
            data.append("middle_name", middleName);
            data.append("last_name", lastName);
            data.append("email", email);
            data.append("phone", phone);
            data.append("dob", dob);
            data.append("state_id", stateId);
            data.append("country_id", countryId);
            data.append("pincode", pincode);
            data.append("lat_lng", lat_lng);
            data.append("company_name", companyName);
            data.append("address", address);
            // var config = {
            //   method: "post",
            //   url: apiUrl + "profile-completed",
            //   headers: {
            //     Accept: "application/json",
            //     Authorization: "Bearer " + localStorage.getItem("token"),
            //   },
            //   data: data,
            // };
            // axios(config)
            profileComplete(data)
                .then(function (response) {
                    if (response.data.status) {
                        // console.log("profile data saved");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        // eslint-disable-next-line
    }, [isFinished]);

    const enterSite = () => window.location.replace("/dashboard");

    function moveToNext() {
        console.log("skip");
    }

    function setNextStep() {
        console.log("Hello! I am an alert box!!");
    }
    function sendMassageToChat(event) {
        var chatText = chatInput;
        var postcode = document.getElementsByClassName("msger-input")[0]?.value;
        if (intent === "pincode") {
            chatText = postcode;
        }
        console.log("chatText", chatText);

        if (chatText != "" || intent === "middle_name") {
            setErrMsg("");
            if (chatText !== "") {
                var targetDiv = document.getElementById("chat-text");
                var userChatRightDiv = document.createElement("div");
                var userChatRightClass = document.createAttribute("class");
                var userChatRightInnerClass = document.createAttribute("class");
                var userChatRightInnerDiv = document.createElement("div");
                var userChatTextDiv = document.createElement("div");
                var userChatTextClass = document.createAttribute("class");
                userChatRightClass.value = "msg right-msg";
                userChatRightInnerClass.value = "msg-bubble";
                userChatTextClass.value = "msg-text";
                userChatRightDiv.setAttributeNode(userChatRightClass);
                userChatRightInnerDiv.setAttributeNode(userChatRightInnerClass);
                var userChatText = document.createTextNode(chatText);
                userChatTextDiv.setAttributeNode(userChatTextClass);
                userChatTextDiv.appendChild(userChatText);
                userChatRightInnerDiv.appendChild(userChatTextDiv);
                userChatRightDiv.appendChild(userChatRightInnerDiv);
                targetDiv.appendChild(userChatRightDiv);
            }

            if (intent === "first_name") {
                setFirstName(chatInput);
            }
            if (intent === "middle_name") {
                setMiddleName(chatInput);
            }
            if (intent === "last_name") {
                setLastName(chatInput);
            }
            if (intent === "email") {
                setEmail(chatInput);
            }
            if (intent === "phone") {
                setPhone(chatInput);
            }
            if (intent === "yob") {
                setDob(chatInput);
            }
            if (intent === "pincode") {
                setPincode(postcode);
            }
            if (intent === "company_name") {
                setCompanyName(chatInput);
            }

            var FormData = require("form-data");
            var data = new FormData();
            data.append("question_number", questionNumber);
            if (intent === "pincode") {
                data.append("answer", postcode);
                data.append("countrycode", countryId);
            } else {
                data.append("answer", chatInput);
            }
            // var config = {
            //   method: "post",
            //   url: apiUrl + "chat-profile",
            //   headers: {
            //     Accept: "application/json",
            //     Authorization: "Bearer " + localStorage.getItem("token"),
            //   },
            //   data: data,
            // };

            // axios(config)
            chatProfile(data)
                .then(function (response) {
                    if (response.data.status) {
                        var targetDiv = document.getElementById("chat-text");
                        var botChatLeftDiv = document.createElement("div");
                        var botChatLeftClass = document.createAttribute("class");
                        var botChatLeftInnerClass = document.createAttribute("class");
                        var botChatLeftImgClass = document.createAttribute("class");
                        var botChatLeftInnerDiv = document.createElement("div");
                        var botChatLeftInnerDiv1 = document.createElement("div");
                        var botChatTextDiv = document.createElement("div");
                        var botChatTextClass = document.createAttribute("class");
                        var questionNo = parseInt(response.data.question_number) + 1;
                        botChatLeftClass.value = "msg left-msg";
                        botChatLeftInnerClass.value = "msg-bubble";
                        botChatLeftImgClass.value = "msg-img";
                        botChatTextClass.value = "msg-text";
                        ref.current.value = "";
                        if (response.data.question.intent === "pincode") {
                            var botChatDiv2 = document.createElement("div");
                            var botChatClass2 = document.createAttribute("class");
                            botChatClass2.value = "msg left-msg";
                            botChatDiv2.setAttributeNode(botChatClass2);
                            var mapChatText = document.getElementById("map");
                            mapChatText.style.display = "block";
                            botChatDiv2.appendChild(mapChatText);
                            targetDiv.appendChild(botChatDiv2);
                            document.getElementsByName("chat-input")[0].value = pincode;
                        }

                        setChatInput("");
                        setQuestionNumber(response.data.question_number);
                        setIntent(response.data.question.intent);
                        scrollToBottom("chat-text");
                        if (response.data.valid === false) {
                            alert("Please enter a valid input");
                        } else {
                            setStep((questionNo / 8) * 100);
                        }
                        if (response.data.question.intent === "profile_complete") {
                            botChatLeftInnerClass.value = "msg-bubble msg-bubble_2";
                            setFinished(1);
                        }
                        botChatLeftDiv.setAttributeNode(botChatLeftClass);
                        botChatLeftInnerDiv1.setAttributeNode(botChatLeftImgClass);
                        botChatLeftInnerDiv.setAttributeNode(botChatLeftInnerClass);
                        var botChatText = document.createTextNode(
                            response.data.question.question
                        );
                        botChatTextDiv.setAttributeNode(botChatTextClass);
                        botChatTextDiv.appendChild(botChatText);
                        botChatLeftInnerDiv.appendChild(botChatTextDiv);
                        botChatLeftDiv.appendChild(botChatLeftInnerDiv1);
                        botChatLeftDiv.appendChild(botChatLeftInnerDiv);

                        if (response.data.question.intent === "middle_name") {
                            var botChatLeftInnerDiv2 = document.createElement("div");
                            var botChatLeftInnerClass2 = document.createAttribute("class");
                            botChatLeftInnerClass2.value = "msg-note";
                            botChatLeftInnerDiv2.setAttributeNode(botChatLeftInnerClass2);
                            botChatLeftInnerDiv2.innerHTML += "<p>Not mandatory</p>";
                            console.log(botChatLeftInnerDiv);
                            console.log(botChatLeftInnerDiv2);
                            var el = document.createElement("span");
                            el.setAttribute("id", "skip_to_next");
                            el.addEventListener("click", function handleClick(event) {
                                console.log("elementClicked", questionNumber);

                                var qNo = questionNumber + 1;
                                console.log("elementClickeds", qNo);
                                var skipToNext = 1;
                                getChatDataResponse(skipToNext);
                            });
                            el.innerHTML = "Skip";
                            console.log("el", el);
                            botChatLeftInnerDiv2.appendChild(el);
                            botChatLeftDiv.appendChild(botChatLeftInnerDiv2);

                            // const el = document.getElementById("skipstepa");
                            // if (el) {
                            //   console.log("created");
                            //   el.addEventListener("click", function () {
                            //     console.log("sssss");
                            //   });
                            // }
                            // var skips = document.getElementById("skipstep");
                            // skips.onclick = function () {
                            //   console.log("skipts");
                            // };
                        }
                        targetDiv.appendChild(botChatLeftDiv);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            setErrMsg("This field is required");
        }
    }

    function setNextStep() {
        console.log("Hello! I am an alert box!!");
    }
    //console.log(chatArray);
    function setNext(event) {
        console.log("setNext");
    }
    function scrollToBottom(id) {
        var objDiv = document.getElementById(id);
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    function changeInputText(event) {
        setChatInput(event.target.value);
    }
    // function for PhoneInput
    function changeInputTextdfe(value, country, e, formattedValue) {
        setChatInput(formattedValue.replace(/ /g, "-"));
    }

    function handleInputKeyPress(e) {
        if (e.key === "Enter") {
            sendMassageToChat();
            ref.current.value = "";
        }
    }

    return (
        <>
            <AddUserHeader />
            <section>
                <div class="container">
                    <div class="overview-msger">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
                        </div>

                    </div>
                    <button
                        type="button"
                        class="save_changes_btn float-right"
                        onClick={() => window.location.replace("/userDetails")}
                    >
                        Next
                    </button>
                </div>
            </section>
        </>
    );
}

export default SiteOverview;

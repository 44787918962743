import { CLOSEICON } from "../../constants/imagepath";

import "react-toastify/dist/ReactToastify.css";

export default function InformationModal({infoModal, setShowInfoModal}) {
  const handleCloseButton = () => {
    setShowInfoModal();
  };
  return (
    <>
      {infoModal &&
      <div id="info_popup" className="overlay_2">
        <div className="popup1">
               <div className="popup-header custom-close-btn">
                    <a className="close" href="javascript:void(0)">
                        <img
                            alt="closeIcon"
                            src={CLOSEICON}
                            onClick={handleCloseButton}
                        />
                    </a>
                </div>
           
             {/* <p className="signup_and_experience_para">
               Signup And Experience The Benefits Of Novi Marine Brokers
             </p> */}
          <div class="container">
            <div class="row">
                <div class="col-12">
                   <iframe src="https://novimarinebrokers.com/zobot.html"/>
                 </div>
              </div>
          </div>
         </div>
       </div>
      }
    </>
  );
}
